import { ofType } from 'redux-observable';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { navigate } from '@reach/router';
import {
  httpMethods,
  singleFileUpload,
} from '../../../../../services/http-service';
import { UPLOAD_DOCUMENTO } from '../../data.const';
import { setLoaders, setPopupInfo } from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const uploadDocumentoEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENTO),
    map(({ payload }: any) => payload),
    tap(p => console.log(`[epic ${UPLOAD_DOCUMENTO}]`, p)),
    withLatestFrom(state$),
    switchMap(([payload, _state]) => {
      const { items, canNavigate } = payload;
      return concat(
        of(setLoaders({ documentoLoader: true })),
        defer(() =>
          from(items).pipe(
            concatMap((item: any) =>
              from(
                singleFileUpload({
                  path: `documento`,
                  method: httpMethods.POST,
                  body: item,
                })
              ).pipe(
                switchMap(() => EMPTY),
                catchError(err => {
                  console.error(`[epic ${UPLOAD_DOCUMENTO}] error`, err);
                  return EMPTY;
                })
              )
            )
          )
        ),
        of(setLoaders({ documentoLoader: false })).pipe(
          tap(() => {
            if (canNavigate) {
              navigate(`${rootConfig.app_path}/gestionale/documenti`).then(
                () => null
              );
            }
          })
        )
      );
    })
  );

export default uploadDocumentoEpic;
