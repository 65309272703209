import { ofType } from 'redux-observable';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { concat, EMPTY, from, of, iif, defer } from 'rxjs';
import { DOWNLOAD_DOCUMENTS_BY_TYPE } from '../../data.const';
import { downloadDocumentsByType } from '../../../../../services/http/documents.http-service';
import { setLoaders } from '../../data.action';
import { downloadBlob } from '../../../../../utils/downloadBlob';

const epic = (action$: any) =>
  action$.pipe(
    ofType(DOWNLOAD_DOCUMENTS_BY_TYPE),
    map(({ payload }: any) => payload),
    filter((payload: any) => payload?.el?.documents > 0),
    switchMap(({ type, el }: any) =>
      concat(
        of(setLoaders({ documents: true })),
        defer(() =>
          from(downloadDocumentsByType({ id: el?.id, type })).pipe(
            switchMap(({ data }: any) => data.blob()),
            tap((blob: any) => {
              let extension = '';
              if (blob?.type?.includes('word')) {
                extension = '.docx';
              } else if (blob?.type?.includes('pdf')) {
                extension = '.pdf';
              }
              downloadBlob(
                blob,
                el?.documents > 1
                  ? `${el?.title}.zip`
                  : `${el?.title}${extension}`
              );
            }),
            switchMap(() => EMPTY),
            catchError(err => {
              console.log('[download multiple documents error]', err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ documents: false }))
      )
    )
  );

export default epic;
