import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, iif, debounceTime } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_TIMESHEET_CHART_LIST_DOWNLOAD } from '../../data.const';
import {
  setListConfigAction,
  setLoaders,
  storeTimesheetChartListAction,
} from '../../data.action';
import { downloadUrl } from '../../../../../utils/utils';
import { downloadBlob } from '../../../../../utils/downloadBlob';

const retrieveTimesheetChartListDownloadEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_TIMESHEET_CHART_LIST_DOWNLOAD),
    debounceTime(20),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_TIMESHEET_CHART_LIST_DOWNLOAD}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        defer(() =>
          from(
            request({
              path: `timesheet/chartExport`,
              method: httpMethods.POST,
              body,
              noJson: true,
            })
          ).pipe(
            switchMap(({ data }: any) => data.blob()),
            tap((blob: any) => {
              let extension = '';
              if (
                blob?.type?.includes(
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                )
              ) {
                extension = '.xlsx';
              }
              downloadBlob(blob, `Riepilogo_attivita_clienti${extension}`);
            }),
            switchMap(() => EMPTY),
            catchError(err => {
              console.error(
                `[epic ${RETRIEVE_TIMESHEET_CHART_LIST_DOWNLOAD}] error`,
                err
              );
              return EMPTY;
            })
          )
        )
      );
    })
  );

export default retrieveTimesheetChartListDownloadEpic;
