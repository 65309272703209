import { Dispatch } from 'redux';
import { httpMethods, request } from '../../../../services/http-service';
import { setPopupInfo } from '../data.action';

const switchUserAccountThunk =
  (payload: any) => async (dispatch: Dispatch<any>, getState: () => any) => {
    const { body, setLoader } = payload;
    let result;

    if (setLoader) {
      setLoader(true);
    }

    try {
      result = await request({
        path: `user/switch-user-account`,
        method: httpMethods.POST,
        body,
      });
    } catch (error) {
      result = { error: true, message: 'Errore di rete' };
    }

    if (setLoader) {
      setLoader(false);
    }

    if (result.error) {
      dispatch(
        setPopupInfo({
          opened: true,
          message: 'Operazione fallita.',
          type: 'BAD',
          timer: 3000,
        })
      );
    }

    return result;
  };

export default switchUserAccountThunk;
