import { ofType } from 'redux-observable';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { concat, debounceTime, defer, from, of } from 'rxjs';
import { GET_FOLDERS_LIST, setLoadersAction, storeFolderListAction } from '..';
import { httpMethods, request } from '../../../../services/http-service';
import { rootConfig } from '../../../../config/root-config';

const epic = (action$: any) =>
  action$.pipe(
    ofType(GET_FOLDERS_LIST),
    debounceTime(20),
    map(({ payload }: any) => payload),
    tap(p => console.log(`[epic ${GET_FOLDERS_LIST}]`, p)),
    switchMap((body: any) =>
      concat(
        of(setLoadersAction({ folders: true })),
        defer(() =>
          from(
            request({
              path: `folder/list`,
              method: httpMethods.POST,
              body,
              rootApi: rootConfig.endpointDrive,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return {
                total: p?.total,
                result: [...p?.result],
                currentFolderSharingInfo: p?.currentFolderSharingInfo,
              };
            }),
            tap(p => console.log(`[epic GET_FOLDERS_LIST]`, p)),
            // switchMap(() => EMPTY),
            switchMap(data => of(storeFolderListAction(data))),
            catchError(err => {
              console.warn(`[epic ${GET_FOLDERS_LIST}] error`, err);
              return of(storeFolderListAction({ result: [], total: 0 }));
            })
          )
        ),
        of(setLoadersAction({ folders: false }))
      )
    )
  );

export default epic;
