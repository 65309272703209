import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_ANAGRAFICA_CATEGORIA } from '../../data.const';
import {
  setListConfigAction,
  setLoaders,
  setPopupInfo,
  storeAnagraficaCategoriaAction,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const retrieveAnagraficaCategoriaEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_ANAGRAFICA_CATEGORIA),
    map(() => ({
      page: 1,
      pageSize: rootConfig.populateFields,
      order: [
        ['account_id', 'asc'],
        ['nome', 'asc'],
      ],
    })),
    // tap(p => console.log(`[epic ${RETRIEVE_ANAGRAFICA_CATEGORIA}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ anagraficaLoader: true })),
        of(setListConfigAction({ role: body })),
        defer(() =>
          from(
            request({
              path: `anagraficaCategoria/list`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return { total: p?.total, result: [...p?.result] };
            }),
            // tap(p => console.log(`[epic ${RETRIEVE_ANAGRAFICA_CATEGORIA}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(data => of(storeAnagraficaCategoriaAction(data))),
            catchError(err => {
              console.error(
                `[epic ${RETRIEVE_ANAGRAFICA_CATEGORIA}] error`,
                err
              );
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ anagraficaLoader: false }))
      );
    })
  );

export default retrieveAnagraficaCategoriaEpic;
