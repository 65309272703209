import { ofType } from 'redux-observable';
import { concat, of, switchMap, EMPTY } from 'rxjs';
import { SET_FILTERS } from '..';

const epic = (action$: any) =>
  action$.pipe(
    ofType(SET_FILTERS),
    switchMap(() => concat(EMPTY))
  );

export default epic;
