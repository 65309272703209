import { ofType } from 'redux-observable';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, timer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { UPSERT_DIPARTIMENTO } from '../../data.const';
import {
  retrieveDipartimentoListAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';

const upsertDipartimentoEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_DIPARTIMENTO),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, ...rest } = payload;
      if (rest.id === 'crea') {
        delete rest.id;
      }
      return rest;
    }),
    tap(p => console.log(`[epic ${UPSERT_DIPARTIMENTO}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ anagraficaLoader: true, page: true })),
        defer(() =>
          from(
            request({
              path: `dipartimento`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            switchMap(data =>
              concat(
                of(retrieveDipartimentoListAction()),
                of(null).pipe(
                  switchMap(() =>
                    of(
                      setPopupInfo({
                        opened: true,
                        message: 'Operazione completata con successo',
                        timer: 3000,
                      })
                    )
                  )
                )
              )
            ),
            catchError(err => {
              console.error(`[epic ${UPSERT_DIPARTIMENTO}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Operazione non riuscita',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ anagraficaLoader: false, page: false }))
      );
    })
  );

export default upsertDipartimentoEpic;
