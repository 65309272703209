import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { UPSERT_ACCOUNT } from '../../data.const';
import {
  setLoaders,
  setPopupInfo,
  storeAccountAction,
} from '../../data.action';
import { getUser } from '../../../auth';

const upsertAccountEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_ACCOUNT),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, ...rest } = payload;
      return rest;
    }),
    // tap(p => console.log(`[epic ${UPSERT_ACCOUNT}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, state]) => {
      const user = getUser(state);
      return concat(
        of(setLoaders({ account: true, page: true })),
        defer(() =>
          from(
            request({ path: `account`, method: httpMethods.POST, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            // tap(p => console.log(`[epic ${UPSERT_ACCOUNT} response]`, p)),
            switchMap(data =>
              of(
                storeAccountAction(data),
                setPopupInfo({
                  opened: true,
                  message: 'Operazione completata con successo',
                  timer: 3000,
                })
              )
            ),
            catchError(err => {
              console.error(`[epic ${UPSERT_ACCOUNT}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Operazione non riuscita',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ account: false, page: false }))
      );
    })
  );

export default upsertAccountEpic;
