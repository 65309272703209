/* eslint-disable no-unused-vars */
const isBrowser = () => typeof window !== 'undefined';

export enum StorageKeys {
  USER = '@user',
}

export const getItem = (key: string) =>
  isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key) as string)
    : {};

export const setItem = (key: string, item: any) =>
  window.localStorage.setItem(key, JSON.stringify(item));

export const deleteItem = (key: string) => {
  window.localStorage.removeItem(key);
};
