import { ofType } from 'redux-observable';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { concat, EMPTY, from, of, timer } from 'rxjs';
import { navigate } from '@reach/router';
import {
  CREATE_PROJECT_DUE_DILIGENCE,
  getProjectsDueDiligence,
  setLoaders,
  setPopupInfo,
} from '../../index';
import { createProjectDueDiligence } from '../../../../../services/http/progetti.http-service';
import { rootConfig } from '../../../../../config/root-config';

const epic = (action$: any) =>
  action$.pipe(
    ofType(CREATE_PROJECT_DUE_DILIGENCE),
    map(({ payload }: any) => payload),
    map(text => ({
      name: text,
      info: text,
    })),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ categories: true })),
        from(createProjectDueDiligence(payload)).pipe(
          switchMap(() => of(getProjectsDueDiligence(null))),
          catchError(() => EMPTY)
        ),
        of(setLoaders({ categories: false })).pipe(
          delay(300),
          tap(() => navigate(`${rootConfig.app_path}/due-diligence/pratiche`))
        ),
        of(
          setPopupInfo({
            opened: true,
            message: 'Pratica creata con successo.',
          })
        ),
        timer(3000).pipe(
          switchMap(() =>
            of(
              setPopupInfo({
                opened: false,
                message: '',
              })
            )
          )
        )
      )
    )
  );

export default epic;
