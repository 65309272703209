import { STATE_KEY } from './data.const';

const mainSelector = (state: any) => state[STATE_KEY];

export const selectTipologie = (state: any) => mainSelector(state).tipologie;
export const selectProgetti = (state: any) => mainSelector(state).progetti;
export const selectFilters = (state: any) => mainSelector(state).filters;
export const selectLoaders = (state: any) => mainSelector(state).loaders;
export const selectDocumenti = (state: any) => mainSelector(state).documenti;
export const selectPopupInfo = (state: any) => mainSelector(state).popupInfo;
export const selectSearchDocumentsText = (state: any) =>
  mainSelector(state).searchDocumentsText;
// due diligence
export const selectDocumentiDueDiligence = (state: any) =>
  mainSelector(state).documentiDueDiligence;
export const selectProgettiDueDiligence = (state: any) =>
  mainSelector(state).progettiDueDiligence;

// gestionale
export const selectAccount = (state: any) => mainSelector(state).account;
export const selectUserList = (state: any) => mainSelector(state).userList;
export const selectCurrentUser = (state: any) =>
  mainSelector(state).currentUser;
export const selectListConfig = (state: any) => mainSelector(state).listConfig;
export const selectRoles = (state: any) => mainSelector(state).roles;
export const selectCurrentRole = (state: any) =>
  mainSelector(state).currentRole;
export const selectPermissions = (state: any) =>
  mainSelector(state).permissions;
// anagrafica
export const selectRelazioneTipoList = (state: any) =>
  mainSelector(state).relazioneTipoList;
export const selectGruppoList = (state: any) => mainSelector(state).gruppoList;
export const selectCurrentGruppo = (state: any) =>
  mainSelector(state).currentGruppo;
export const selectNaturaGiuridica = (state: any) =>
  mainSelector(state).naturaGiuridica;
export const selectAnagraficaCategoria = (state: any) =>
  mainSelector(state).anagraficaCategoria;
export const selectAnagraficaSpecifica = (state: any) =>
  mainSelector(state).anagraficaSpecifica;
export const selectAnagraficaList = (state: any) =>
  mainSelector(state).anagraficaList;
export const selectCurrentAnagrafica = (state: any) =>
  mainSelector(state).currentAnagrafica;
export const selectPraticaMateria = (state: any) =>
  mainSelector(state).praticaMateria;
export const selectPraticaList = (state: any) =>
  mainSelector(state).praticaList;
export const selectCurrentPratica = (state: any) =>
  mainSelector(state).currentPratica;
export const selectIncaricoList = (state: any) =>
  mainSelector(state).incaricoList;
export const selectCurrentIncarico = (state: any) =>
  mainSelector(state).currentIncarico;
export const selectDipartimentoList = (state: any) =>
  mainSelector(state).dipartimentoList;
// timesheet
export const selectTimesheetTipoList = (state: any) =>
  mainSelector(state).timesheetTipoList;
export const selectTimesheetList = (state: any) =>
  mainSelector(state).timesheetList;
export const selectCurrentTimesheet = (state: any) =>
  mainSelector(state).currentTimesheet;
// evento
export const selectEventoTipoList = (state: any) =>
  mainSelector(state).eventoTipoList;
export const selectEventoList = (state: any) => mainSelector(state).eventoList;
export const selectCurrentEvento = (state: any) =>
  mainSelector(state).currentEvento;
// documento
export const selectDocumentoTipoList = (state: any) =>
  mainSelector(state).documentoTipoList;
export const selectDocumentoCategoriaList = (state: any) =>
  mainSelector(state).documentoCategoriaList;
export const selectDocumentoList = (state: any) =>
  mainSelector(state).documentoList;
export const selectCurrentDocumento = (state: any) =>
  mainSelector(state).currentDocumento;
// general
export const selectCurrentCreateSession = (state: any, label: string) =>
  mainSelector(state).currentCreateSession?.[label];
// onorario
export const selectTariffaOrariaList = (state: any) =>
  mainSelector(state).tariffaOrariaList;
export const selectOnorarioList = (state: any) =>
  mainSelector(state).onorarioList;
export const selectCurrentOnorario = (state: any) =>
  mainSelector(state).currentOnorario;
export const selectRataList = (state: any) => mainSelector(state).rataList;
export const selectCurrentRata = (state: any) =>
  mainSelector(state).currentRata;
// onorario incarico
export const selectOnorarioIncaricoList = (state: any) =>
  mainSelector(state).onorarioIncaricoList;
export const selectCurrentOnorarioIncarico = (state: any) =>
  mainSelector(state).currentOnorarioIncarico;
export const selectRataIncaricoList = (state: any) =>
  mainSelector(state).rataIncaricoList;
export const selectCurrentRataIncarico = (state: any) =>
  mainSelector(state).currentRataIncarico;
// search
export const selectSearch = (state: any) => mainSelector(state).search;
export const selectPreload = (state: any) => mainSelector(state).preload;
// fattura
export const selectSpeseGeneraliList = (state: any) =>
  mainSelector(state).speseGeneraliList;
export const selectFatturaList = (state: any) =>
  mainSelector(state).fatturaList;
export const selectCurrentFattura = (state: any) =>
  mainSelector(state).currentFattura;
export const selectNotulaList = (state: any) => mainSelector(state).notulaList;
// onorario fattura
export const selectOnorarioFatturaList = (state: any) =>
  mainSelector(state).onorarioFatturaList;
export const selectCurrentOnorarioFattura = (state: any) =>
  mainSelector(state).currentOnorarioFattura;
export const selectRataFatturaList = (state: any) =>
  mainSelector(state).rataFatturaList;
export const selectCurrentRataFattura = (state: any) =>
  mainSelector(state).currentRataFattura;
// timesheet chart
export const selectTimesheetChartList = (state: any) =>
  mainSelector(state).timesheetChartList;
// visure
export const selectVisurePrices = (state: any) =>
  mainSelector(state).visurePrices;
// deposito
export const selectDepositoList = (state: any) =>
  mainSelector(state).depositoList;
export const selectCurrentDeposito = (state: any) =>
  mainSelector(state).currentDeposito;
// notifica
export const selectNotificaList = (state: any) =>
  mainSelector(state).notificaList;
export const selectCurrentNotifica = (state: any) =>
  mainSelector(state).currentNotifica;
// configuration
export const selectConfigurationList = (state: any) =>
  mainSelector(state).configurationList;
export const selectCurrentConfiguration = (state: any) =>
  mainSelector(state).currentConfiguration;
// modals
export const selectModalState = (state: any) => mainSelector(state).modalState;
// notices
export const selectUnreadNotices = (state: any) =>
  mainSelector(state).unreadNotices;
export const selectFilePreview = (state: any) =>
  mainSelector(state).filePreview;
