import { CSSProperties } from 'react';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
  `;

const styles: { [name: string]: CSSProperties } = {
  header: {
    marginBottom: 8,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    margin: 8,
    width: 'calc(100% - 16px)',
  },
  body: {
    //
  },
};

export default styles;
