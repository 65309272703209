import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, merge } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { DELETE_FATTURA } from '../../data.const';
import {
  retrieveFatturaListAction,
  retrieveNotulaListAction,
  setLoaders,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const deleteFatturaEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DELETE_FATTURA),
    map(({ payload: { ids, ...rest } }: any) => ({
      body: {
        ids,
      },
      listBody: rest,
    })),
    // tap(p => console.log(`[epic ${DELETE_FATTURA}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, listBody }, _state]) => {
      return concat(
        of(setLoaders({ fatturaLoader: true })),
        defer(() =>
          from(
            request({ path: `fattura`, method: httpMethods.DELETE, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p;
            }),
            // tap(p => console.log(`[epic ${DELETE_FATTURA}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(() =>
              merge(
                of(
                  retrieveFatturaListAction({
                    order: [['updated_at', 'desc']],
                    page: 1,
                    pageSize: rootConfig.pageSize,
                    filters: {
                      and: [
                        {
                          field: 'tipo',
                          value: 'Fattura',
                        },
                      ],
                    },
                  })
                ),
                of(
                  retrieveNotulaListAction({
                    order: [['updated_at', 'desc']],
                    page: 1,
                    pageSize: rootConfig.pageSize,
                    filters: {
                      and: [
                        {
                          field: 'tipo',
                          value: 'Notula',
                        },
                      ],
                    },
                  })
                )
              )
            ),
            catchError(err => {
              console.error(`[epic ${DELETE_FATTURA}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ fatturaLoader: false }))
      );
    })
  );

export default deleteFatturaEpic;
