import {
  LOGIN,
  LOGOUT,
  REGISTER,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  RETRIEVE_AUTH_STATE,
  UPDATE_USER,
  GET_USER_CREDITS,
  GET_USER_DATA,
  LOGOUT_END,
} from './auth.const';

/*
ACTIONS
*/

export const login = (payload: any) => ({ type: LOGIN, payload });
export const logout = () => ({ type: LOGOUT, payload: null });
export const logoutEndAction = () => ({ type: LOGOUT_END });
export const register = (payload: any) => ({ type: REGISTER, payload });
export const loginSuccess = (payload: any) => ({
  type: LOGIN_SUCCESS,
  payload,
});
export const loginError = (payload: string | null) => ({
  type: LOGIN_ERROR,
  payload,
});
export const retrieveAuthState = (payload: string | null) => ({
  type: RETRIEVE_AUTH_STATE,
  payload,
});
export const updateUserAction = (payload: any) => ({
  type: UPDATE_USER,
  payload,
});
export const getUserCreditsAction = (payload: any = null) => ({
  type: GET_USER_CREDITS,
  payload,
});
export const getUserDataAction = (payload: any = null) => ({
  type: GET_USER_DATA,
  payload,
});
