import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { DOWNLOAD_DOCUMENTO } from '../../data.const';
import {
  setLoaders,
  setPopupInfo,
  upsertDocumentoAction,
} from '../../data.action';
import { downloadUrl, generateFileFromUrl } from '../../../../../utils/utils';
import { uploadDocuments } from '../../../../../services/http/documents.http-service';

const downloadDocumentoEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DOWNLOAD_DOCUMENTO),
    map(({ payload: { items, type, ...rest } }: any) => ({
      body: {
        items,
        type,
      },
      listBody: rest,
    })),
    tap(p => console.log(`[epic ${DOWNLOAD_DOCUMENTO}]`, p)),
    // switchMap(() => EMPTY),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          body: { items, type },
          listBody,
        },
        _state,
      ]) => {
        return concat(
          of(setLoaders({ documentoLoader: true })),
          defer(() =>
            from(
              request({
                path: `documento/download/${items?.[0]?.id}`,
                method: httpMethods.GET,
              })
            ).pipe(
              map((p: any) => {
                if (p?.status > 299) {
                  throw new Error('Server error');
                }
                return p?.result;
              }),
              tap(p => console.log(`[epic ${DOWNLOAD_DOCUMENTO}]`, p)),
              tap(p => {
                if (type === 'visualizza') {
                  window.open(p);
                } else if (type === 'irnerio') {
                  // don't tap :)
                } else {
                  downloadUrl(p);
                }
              }),
              switchMap(p => {
                if (type === 'irnerio') {
                  return from(generateFileFromUrl(p)).pipe(
                    switchMap(f => {
                      return from(uploadDocuments({ files: [f] })).pipe(
                        switchMap((p2: any) => {
                          const { error } = p2;
                          if (!error) {
                            return of(
                              upsertDocumentoAction({
                                listBody,
                                id: items?.[0]?.id,
                                irnerio: 'yes',
                              })
                            );
                          }
                          return of(
                            setPopupInfo({
                              opened: true,
                              message: 'File non supportato da Irnerio',
                              type: 'BAD',
                              timer: 3000,
                            })
                          );
                        })
                      );
                    })
                  );
                }
                return EMPTY;
              }),
              catchError(err => {
                console.error(`[epic ${DOWNLOAD_DOCUMENTO}] error`, err);
                return EMPTY;
              })
            )
          ),
          of(setLoaders({ documentoLoader: false }))
        );
      }
    )
  );

export default downloadDocumentoEpic;
