import { Dispatch } from 'redux';
import { httpMethods, request } from '../../../../services/http-service';
import { setPopupInfo, storeUnreadNotices } from '../data.action';
import { selectUnreadNotices } from '../data.selector';

const retrieveNoticesThunk =
  (payload: any) => async (dispatch: Dispatch<any>, getState: () => any) => {
    const state = getState();
    const { body, setLoader, withDispatch } = payload;
    let result;

    if (setLoader) {
      setLoader(true);
    }

    try {
      result = await request({
        path: `richiesta/list`,
        method: httpMethods.POST,
        body: {
          ...body,
        },
      });
    } catch (error) {
      result = { error: true, message: 'Errore di rete' };
    }

    if (setLoader) {
      setLoader(false);
    }

    if (result?.error) {
      //
    } else if (withDispatch === true) {
      if (result?.total > 0 && selectUnreadNotices(state) === false) {
        dispatch(
          setPopupInfo({
            opened: true,
            message: 'Hai delle nuove notifiche.',
            timer: 3000,
          })
        );
      }
      dispatch(storeUnreadNotices(result.total > 0));
    } else {
      dispatch(storeUnreadNotices(false));
    }

    return result;
  };

export default retrieveNoticesThunk;
