import {
  SET_CURRENT_FOLDER,
  SET_LOADERS,
  STORE_FOLDER_LIST,
  SET_GLOBAL_FILTERS,
  RESET_GLOBAL_FILTERS,
} from './drive.const';

/*
INITIAL STATE
*/

const initialState = {
  folders: {},
  files: {},
  loaders: {},
  currentFolder: '/',
  globalFilters: {},
};

/*
ACTION HANDLERS
*/

const actionHandlers = {
  [SET_LOADERS]: (state: any, payload: any) => ({
    ...state,
    loaders: { ...state.loaders, ...payload } || {},
  }),
  [STORE_FOLDER_LIST]: (state: any, payload: any) => ({
    ...state,
    folders: payload ?? [],
  }),
  [SET_CURRENT_FOLDER]: (state: any, payload: any) => ({
    ...state,
    currentFolder: payload,
  }),
  [SET_GLOBAL_FILTERS]: (state: any, payload: any) => {
    const { source, ...rest } = payload;
    return {
      ...state,
      globalFilters:
        {
          ...state.globalFilters,
          [source]: {
            ...state.globalFilters[source],
            ...rest,
          },
        } || {},
    };
  },
  [RESET_GLOBAL_FILTERS]: (state: any, payload: any) => ({
    ...state,
    globalFilters: {
      ...state.globalFilters,
      [payload]: null,
    },
  }),
};

/*
REDUCER
*/

const driveReducer = (
  state: any = initialState,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default driveReducer;
