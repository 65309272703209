import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { DELETE_RATA_FATTURA } from '../../data.const';
import {
  retrieveRataFatturaListAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';

const deleteRataFatturaEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DELETE_RATA_FATTURA),
    map(({ payload: { ids, ...rest } }: any) => ({
      body: {
        ids,
      },
      listBody: rest,
    })),
    // tap(p => console.log(`[epic ${DELETE_RATA_FATTURA}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, listBody }, _state]) => {
      return concat(
        of(setLoaders({ fatturaLoader: true })),
        defer(() =>
          from(
            request({ path: `rataFattura`, method: httpMethods.DELETE, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p;
            }),
            // tap(p => console.log(`[epic ${DELETE_RATA_FATTURA}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(() => of(retrieveRataFatturaListAction(listBody))),
            catchError(err => {
              console.error(`[epic ${DELETE_RATA_FATTURA}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ fatturaLoader: false }))
      );
    })
  );

export default deleteRataFatturaEpic;
