import { ofType } from 'redux-observable';
import { mapTo } from 'rxjs';
import { STORE_SEARCH_DOCUMENTS } from '../../data.const';
import { getDocuments } from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const epic = (action$: any) =>
  action$.pipe(
    ofType(STORE_SEARCH_DOCUMENTS),
    mapTo(getDocuments({ page: 1, pageSize: rootConfig.pageSize }))
  );

export default epic;
