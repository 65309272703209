import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_ONORARIO } from '../../data.const';
import {
  setCurrentOnorarioAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';

const retrieveOnorarioEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_ONORARIO),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_ONORARIO}]`, p)),
    withLatestFrom(state$),
    switchMap(([id, _state]) => {
      return concat(
        of(setLoaders({ onorarioLoader: true })),
        defer(() =>
          from(
            request({ path: `onorario/${id}`, method: httpMethods.GET })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p?.result;
            }),
            // tap(p => console.log(`[epic ${RETRIEVE_ONORARIO}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(p => of(setCurrentOnorarioAction(p))),
            catchError(err => {
              console.error(`[epic ${RETRIEVE_ONORARIO}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ onorarioLoader: false }))
      );
    })
  );

export default retrieveOnorarioEpic;
