import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, throwError } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_ACCOUNT } from '../../data.const';
import {
  setLoaders,
  setPopupInfo,
  storeAccountAction,
} from '../../data.action';
import { getUser } from '../../../auth';

const retrieveAccountEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_ACCOUNT),
    map(({ payload }: any) => payload),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const user = getUser(state);
      const { irnerio_account_id } = user;
      let { AccountId } = user;
      if (irnerio_account_id) {
        AccountId = irnerio_account_id;
      }
      return concat(
        of(setLoaders({ account: true })),
        defer(() =>
          from(
            request({ path: `account/${AccountId}`, method: httpMethods.GET })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            tap(p => console.log(`[epic ${RETRIEVE_ACCOUNT}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(data => of(storeAccountAction(data))),
            catchError(err => {
              console.error(`[epic ${RETRIEVE_ACCOUNT}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ account: false }))
      );
    })
  );

export default retrieveAccountEpic;
