import { ofType } from 'redux-observable';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, timer } from 'rxjs';
import { navigate } from '@reach/router';
import { httpMethods, request } from '../../../../../services/http-service';
import { UPSERT_USER } from '../../data.const';
import {
  setCurrentUserAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const upsertUserEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_USER),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, onDone, ...rest } = payload;
      if (rest.id === 'crea') {
        delete rest.id;
      }
      return { body: rest, onDone };
    }),
    tap(p => console.log(`[epic ${UPSERT_USER}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, onDone }, state]) => {
      return concat(
        of(setLoaders({ userLoader: true, page: true })),
        defer(() =>
          from(request({ path: `user`, method: httpMethods.POST, body })).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            switchMap(data =>
              concat(
                of(setCurrentUserAction(data)),
                of(null).pipe(
                  tap(() => {
                    if (body?.id || typeof onDone === 'function') {
                      onDone?.();
                      return; // do not navigate in case of edit
                    }
                    navigate(`${rootConfig.app_path}/account/utenti`).then(
                      () => null
                    );
                  }),
                  delay(500),
                  switchMap(() =>
                    of(
                      setPopupInfo({
                        opened: true,
                        message: 'Operazione completata con successo',
                        timer: 3000,
                      })
                    )
                  )
                )
              )
            ),
            catchError(err => {
              console.error(`[epic ${UPSERT_USER}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message,
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ userLoader: false, page: false }))
      );
    })
  );

export default upsertUserEpic;
