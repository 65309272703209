/* eslint-disable no-nested-ternary */
import moment from 'moment';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// requires
require('dayjs/locale/it');

// component functions
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.locale('it');
dayjs.updateLocale('it', {
  weekdays: [
    'Domenica',
    'Lunedì',
    'Martedì',
    'Mercoledì',
    'Giovedì',
    'Venerdì',
    'Sabato',
  ],
});

export const isEmail = (email: string) =>
  email && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

export const isPasswordOk = (field: string) =>
  field.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);

export const filler = Array.from({ length: 100 }, () => ({}));

export const getUserPermissions = (user: any) => {
  const permissions: any = {};
  user?.Roles?.forEach((role: any) => {
    role.Permissions.forEach((permission: any) => {
      const { c, r, u, d, s } = permission.RolePermissions || {};
      permissions[permission.nome] = { c, r, u, d, s };
    });
  });

  user?.Permissions?.forEach((permission: any) => {
    const { c, r, u, d, s } = permission.UserPermissions || {};
    permissions[permission.nome] = {
      c: c || permissions?.[permission.nome]?.c,
      r: r || permissions?.[permission.nome]?.r,
      u: u || permissions?.[permission.nome]?.u,
      d: d || permissions?.[permission.nome]?.d,
      s: s || permissions?.[permission.nome]?.s,
    };
  });

  return permissions;
};

export const checkPermissions = (
  permissions: any,
  neededPermissions: string[],
  types: string[],
  { withAll } = { withAll: ['Tutti'] },
) => {
  const permissionsToCheck = [...withAll, ...neededPermissions];
  const result = permissionsToCheck.filter(permissionName => {
    const permission = permissions[permissionName];
    if (permission) {
      const typeResult = types.filter(type => {
        const rolePermissionCheck = permission?.[type];
        const userPermissionCheck = permission?.[type];
        return rolePermissionCheck || userPermissionCheck;
      });
      return !!typeResult.length;
    }
    return false;
  });

  return result.length;
};

export const minutesConverter = (m: number) => {
  const hours = m / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return `${rhours || '0'}:${rminutes || '00'}`;
};

export const minutesConverterForTimeInput = (m: number) => {
  const hours = +m / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  let rminutes: string | number = Math.round(minutes);
  rminutes = rminutes < 10 ? `0${rminutes}` : rminutes;
  rminutes = rminutes === '00' ? '' : rminutes;
  return `${rhours || '0'}${rminutes ? ':' : ''}${rminutes}`;
};

export const minutesConverterForTimesheetHead = (m: number) => {
  const hours = +m / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  let rminutes: string | number = Math.round(minutes);
  rminutes = rminutes < 10 ? `0${rminutes}` : rminutes;
  rminutes = rminutes === '00' ? '' : rminutes;
  return `${rhours || '0'}h ${rminutes ? `${rminutes}min` : ''}`;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getMonthProperties = (currentMonth: string) => {
  moment.locale('it');
  const month = moment(currentMonth).locale('it');
  const nextMonthMoment = month.clone();
  const currentMonthMoment = month.clone();
  const daysInMonth = Array.from(
    { length: month.daysInMonth() },
    (_, i) => i + 1,
  );
  let finalDaysWithProps: any[] = [];
  const firstDayOfMonthNotFormatted = month.startOf('month');
  const lastDayOfMonthNotFormatted = nextMonthMoment.endOf('month');

  let firstDay: number | string = firstDayOfMonthNotFormatted.format('d');
  firstDay = +firstDay;
  firstDay = firstDay === 0 ? 7 : firstDay;

  let lastDay: number | string = lastDayOfMonthNotFormatted.format('d');
  lastDay = +lastDay;
  lastDay = lastDay === 0 ? 7 : lastDay;

  // previous month
  const previousMonthWeekStartNotFormatted =
    firstDayOfMonthNotFormatted.subtract(1, 'days');
  const previousMonthWeekStart = previousMonthWeekStartNotFormatted.format('D');
  const previousMonthString = previousMonthWeekStartNotFormatted.format('MMM');
  const previousMonthNumber = previousMonthWeekStartNotFormatted.format('MM');
  const previousYearNumber = previousMonthWeekStartNotFormatted.format('YYYY');
  // next month
  const nextMonthWeekStartNotFormatted = lastDayOfMonthNotFormatted.add(
    1,
    'days',
  );
  const nextMonthString = nextMonthWeekStartNotFormatted.format('MMM');
  const nextMonthNumber = nextMonthWeekStartNotFormatted.format('MM');
  const nextYearNumber = nextMonthWeekStartNotFormatted.format('YYYY');
  const currentMonthString = currentMonthMoment.format('MMM');
  const currentMonthNumber = currentMonthMoment.format('MM');
  const currentYearNumber = currentMonthMoment.format('YYYY');

  const daysInMonthWithProps = daysInMonth.map((day: number) => ({
    day,
    date: moment(
      `${currentMonthNumber}/${day}/${currentYearNumber}`,
      'MM/DD/YYYY',
    )
      .utc()
      .toISOString(),
    month: currentMonthNumber,
    monthString: currentMonthString,
    year: currentYearNumber,
    current: true,
    data: [],
  }));

  finalDaysWithProps = daysInMonthWithProps.slice();

  for (
    let i = +previousMonthWeekStart;
    i > +previousMonthWeekStart - (firstDay - 1);
    i--
  ) {
    finalDaysWithProps.unshift({
      day: i,
      date: moment(
        `${previousMonthNumber}/${i}/${previousYearNumber}`,
        'MM/DD/YYYY',
      )
        .utc()
        .toISOString(),
      previous: true,
      year: previousYearNumber,
      month: previousMonthNumber,
      monthString: previousMonthString,
      data: [],
    });
  }

  const finalDaysLen =
    7 - lastDay + finalDaysWithProps.length - (finalDaysWithProps.length - 1);

  for (let i = 1; i < finalDaysLen; i++) {
    finalDaysWithProps.push({
      day: i,
      date: moment(`${nextMonthNumber}/${i}/${nextYearNumber}`, 'MM/DD/YYYY')
        .utc()
        .toISOString(),
      next: true,
      month: nextMonthNumber,
      monthString: nextMonthString,
      year: nextYearNumber,
      data: [],
    });
  }

  return {
    daysInMonth,
    finalDaysWithProps,
    firstDay,
    previousMonthWeekStart,
  };
};

export const downloadUrl = (url: string, name = 'file.png') => {
  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = url;
  link.target = '_blank';
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);
};

export const timeToDecimal = (time: string) => {
  const hoursMinutes = (time || '').split(/[.:]/);
  const hours = parseInt(hoursMinutes[0], 10);
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return hours + minutes / 60;
};

export const timeDiffToDecimal = (startTime: string, endTime: string) => {
  const start = timeToDecimal(startTime);
  const end = timeToDecimal(endTime);
  return end - start;
};

export const toEurValue = (value: number) => {
  const it = Intl.NumberFormat('it-IT', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `€ ${it.format(value)}`;
};

export const generateFileFromUrl = async (url: string): Promise<File> => {
  const response = await fetch(url, { mode: 'no-cors' });
  const data = await response.blob();
  const filename = url
    .split('?')?.[0]
    ?.substring(url.split('?')?.[0]?.lastIndexOf('/') + 1);
  return new File([data], filename);
};

export const checkPassword = str => {
  const re = /^(?=.*\d)(?=.*[!@#$,;\-:.%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
};

export const checkIban = input => {
  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest

  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter =>
    (letter.charCodeAt(0) - 55).toString(),
  );
  // final check
  return mod97(digits);
};

function mod97(string) {
  let checksum = string.slice(0, 2);
  let fragment;
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export function greet(date: dayjs.Dayjs) {
  const hour = Number(date.format('H'));

  const splitAfternoon = 12; //24hr time to split the afternoon
  const splitEvening = 18; //24hr time to split the evening

  const isMorning = hour >= 5 && hour < splitAfternoon;
  const isAfternoon = splitAfternoon <= hour && hour < splitEvening;

  if (isMorning) {
    return 'Buongiorno';
  }
  if (isAfternoon) {
    return 'Buon pomeriggio';
  }

  return 'Buonasera';
}

export function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!

  return (
    // @ts-ignore
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function rawurlencode(str) {
  //       discuss at: https://locutus.io/php/rawurlencode/
  //      original by: Brett Zamir (https://brett-zamir.me)
  //         input by: travc
  //         input by: Brett Zamir (https://brett-zamir.me)
  //         input by: Michael Grier
  //         input by: Ratheous
  //      bugfixed by: Kevin van Zonneveld (https://kvz.io)
  //      bugfixed by: Brett Zamir (https://brett-zamir.me)
  //      bugfixed by: Joris
  // reimplemented by: Brett Zamir (https://brett-zamir.me)
  // reimplemented by: Brett Zamir (https://brett-zamir.me)
  //           note 1: This reflects PHP 5.3/6.0+ behavior
  //           note 1: Please be aware that this function expects \
  //           note 1: to encode into UTF-8 encoded strings, as found on
  //           note 1: pages served as UTF-8
  //        example 1: rawurlencode('Kevin van Zonneveld!')
  //        returns 1: 'Kevin%20van%20Zonneveld%21'
  //        example 2: rawurlencode('https://kvz.io/')
  //        returns 2: 'https%3A%2F%2Fkvz.io%2F'
  //        example 3: rawurlencode('https://www.google.nl/search?q=Locutus&ie=utf-8')
  //        returns 3: 'https%3A%2F%2Fwww.google.nl%2Fsearch%3Fq%3DLocutus%26ie%3Dutf-8'
  // eslint-disable-next-line no-param-reassign
  str += '';
  // Tilde should be allowed unescaped in future versions of PHP (as reflected below),
  // but if you want to reflect current
  // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
  return encodeURIComponent(str)
    .replace(/!/g, '%21')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/\*/g, '%2A');
}

export const checkIfFileHasRole = roles => {
  return !roles?.find(el =>
    el.Users?.find(el2 => !!el2.UserFileRole?.path_for_share),
  );
};

export const isFolderPathForShare = (f, u) =>
  f?.FileRoles?.find(el =>
    el.Users?.find(
      el2 => +el2.id === +u.id && !!el2.UserFileRole?.path_for_share,
    ),
  );
