import { ofType } from 'redux-observable';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, timer } from 'rxjs';
import { navigate } from '@reach/router';
import { httpMethods, request } from '../../../../../services/http-service';
import { UPSERT_RATA_FATTURA } from '../../data.const';
import {
  retrieveRataFatturaListAction,
  setCurrentRataFatturaAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const upsertRataFatturaEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_RATA_FATTURA),
    tap(p => console.log(`[epic ${UPSERT_RATA_FATTURA}]`, p)),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, ...rest } = payload?.body;
      if (rest.id === 'crea') {
        delete rest.id;
      }
      return { info: payload?.info, body: rest };
    }),
    tap(p => console.log(`[epic ${UPSERT_RATA_FATTURA}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, info }, state]) => {
      return concat(
        of(setLoaders({ fatturaLoader: true, page: true })),
        defer(() =>
          from(
            request({ path: `rataFattura`, method: httpMethods.POST, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            switchMap(data =>
              concat(
                of(setCurrentRataFatturaAction(data)),
                of(null).pipe(
                  delay(500),
                  switchMap(() =>
                    concat(
                      of(
                        setPopupInfo({
                          opened: true,
                          message: 'Operazione completata con successo',
                          timer: 3000,
                        })
                      ),
                      of(
                        retrieveRataFatturaListAction({
                          pageSize: rootConfig.pageSize,
                          page: 1,
                          order: [['descrizione', 'asc']],
                          filters: {
                            or: [
                              {
                                field: '$OnorarioFattura.id$',
                                value: info?.onorarioId,
                              },
                            ],
                          },
                        })
                      ),
                      of(null).pipe(
                        tap(() => {
                          info?.action && info?.action();
                        }),
                        switchMap(() => EMPTY)
                      )
                    )
                  )
                )
              )
            ),
            catchError(err => {
              console.error(`[epic ${UPSERT_RATA_FATTURA}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Operazione non riuscita',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ fatturaLoader: false, page: false }))
      );
    })
  );

export default upsertRataFatturaEpic;
