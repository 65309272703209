import { httpMethods, request } from '../http-service';

const base = 'categories';

const getCategorieList = (query: string) =>
  request({ path: `${base}/flattened`, method: httpMethods.GET, query });

const deleteCategory = (id: string) =>
  request({ path: `${base}/${id}`, method: httpMethods.DELETE });

export { getCategorieList, deleteCategory };
