import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GET_USER_RELATED_DATA } from '../../data.const';
import { getUserRelatedDataSuccess } from '../../data.action';

const mock = {
  tipologie: {
    data: [],
  },
  progetti: [],
};

const getUserRelatedDataEpic = (action$: any) =>
  action$.pipe(
    ofType(GET_USER_RELATED_DATA),
    map(() => mock),
    switchMap(data => of(getUserRelatedDataSuccess(data)))
  );

export default getUserRelatedDataEpic;
