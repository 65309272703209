import { SET_LOADERS } from '../data';
import {
  GET_FOLDERS_LIST,
  RESET_GLOBAL_FILTERS,
  SET_CURRENT_FOLDER,
  SET_CURRENT_FOLDER_ID,
  SET_GLOBAL_FILTERS,
  STORE_FOLDER_LIST,
} from './drive.const';

/*
ACTIONS
*/

export const getFolderListAction = (payload: any) => ({
  type: GET_FOLDERS_LIST,
  payload,
});

export const storeFolderListAction = (payload: any) => ({
  type: STORE_FOLDER_LIST,
  payload,
});

export const setLoadersAction = (payload: any) => ({
  type: SET_LOADERS,
  payload,
});

export const setCurrentFolderAction = (payload: any) => ({
  type: SET_CURRENT_FOLDER,
  payload,
});

export const setCurrentFolderIdAction = (payload: any) => ({
  type: SET_CURRENT_FOLDER_ID,
  payload,
});

export const setGlobalFiltersAction = (payload: any) => ({
  type: SET_GLOBAL_FILTERS,
  payload,
});

export const resetGlobalFiltersAction = (payload: any) => ({
  type: RESET_GLOBAL_FILTERS,
  payload,
});
