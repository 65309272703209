import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_ROLE } from '../../data.const';
import {
  setCurrentRoleAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';

const retrieveRoleEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_ROLE),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_ROLE}]`, p)),
    withLatestFrom(state$),
    switchMap(([id, _state]) => {
      return concat(
        of(setLoaders({ roleLoader: true })),
        defer(() =>
          from(request({ path: `role/${id}`, method: httpMethods.GET })).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p?.result;
            }),
            // tap(p => console.log(`[epic ${RETRIEVE_ROLE}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(p => of(setCurrentRoleAction(p))),
            catchError(err => {
              console.error(`[epic ${RETRIEVE_ROLE}] error`, err);
              return EMPTY;
              // return of(
              //   setPopupInfo({
              //     opened: true,
              //     message: 'Errore nel recupero delle informazioni',
              //     type: 'BAD',
              //     timer: 3000,
              //   })
              // );
            })
          )
        ),
        of(setLoaders({ roleLoader: false }))
      );
    })
  );

export default retrieveRoleEpic;
