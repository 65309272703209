import { ofType } from 'redux-observable';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { concat, EMPTY, from, of, timer } from 'rxjs';
import { navigate } from '@reach/router';
import { UPLOAD_DOCUMENTS } from '../../data.const';
import { getDocuments, setLoaders, setPopupInfo } from '../../data.action';
import {
  setCategory,
  setProject,
  uploadDocuments,
} from '../../../../../services/http/documents.http-service';
import { rootConfig } from '../../../../../config/root-config';

const epic = (action$: any) =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENTS),
    map(({ payload }: any) => payload),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ uploadDocuments: true })),
        from(
          uploadDocuments({
            files: payload.files,
            body: { language: rootConfig.language },
          })
        ).pipe(
          switchMap((documents: any) =>
            from(
              Promise.all(
                documents?.data?.results?.map(async (document: any) => {
                  if (document?.id && payload?.categoria?.id) {
                    await setCategory({
                      id: document?.id,
                      body: { id: payload.categoria.id },
                    });
                  }

                  if (document?.id && payload?.progetto?.id) {
                    await setProject({
                      id: document?.id,
                      body: { id: payload.progetto.id },
                    });
                  }
                })
              )
            )
          ),
          switchMap(() => EMPTY),
          catchError(err => {
            console.log('[files upload error]', err);
            return EMPTY;
          })
        ),
        of(getDocuments({ page: 1, pageSize: 10 })),
        of(setLoaders({ uploadDocuments: false })).pipe(
          delay(300),
          tap(() => navigate(`${rootConfig.app_path}/database/documenti`))
        ),
        of(
          setPopupInfo({
            opened: true,
            message: 'Documento caricato con successo.',
          })
        ),
        timer(3000).pipe(
          switchMap(() =>
            of(
              setPopupInfo({
                opened: false,
                message: '',
              })
            )
          )
        )
      )
    )
  );

export default epic;
