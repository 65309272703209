/* eslint-disable no-restricted-globals */
import * as React from 'react';
import { Box, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import './style.css';
import { connect, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { useCallback, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { selectSearch, setSearchAction } from '../../state/modules/data';
import { appColors } from '../../utils/colors';
import { rootConfig } from '../../config/root-config';

const StyledTextInput = styled<typeof TextInput | any>(TextInput)`
  ${({ open }) => (open ? '' : 'max-width: 0;')}
  color: var(--searchBar);
  color: ${() => appColors.searchBar};
  position: relative;

  ::placeholder {
    color: var(--searchBar);
    color: ${() => appColors.searchBar};
  }
`;

const StyledTextContainer = styled<typeof Box | any>(Box)`
  border-radius: 8px;
  transition: width 200ms ease-out;
  background-color: ${({ open }) => (open ? '#ffffff' : 'transparent')};
  width: ${({ open }) => (open ? '100%' : '0')};
  position: absolute;
  right: 0;
  height: 95%;
`;

const StyledSearchIconBox = styled<typeof Box | any>(Box)`
  transition: all 200ms ease-out;
  border-radius: ${({ open }) => (open ? '100%' : '8px')};
  width: ${({ open }) => (open ? '30px' : '45px')};
  height: 30px;
  position: absolute;
  right: 2px;
`;

const StyledContainer = styled<typeof Box | any>(Box)`
  border-radius: 8px;
  background-color: transparent;
  position: relative;
`;

const Component = ({ setSearch }: any) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lazySearch = useCallback(debounce(setSearch, 500), [setSearch]);
  const location = useLocation();
  const searchField: string = useSelector(state => selectSearch(state));
  const [currentSearch, setCurrentSearch] = useState<string>(searchField);
  const [searchOpened, set_searchOpened] = useState<boolean>(false);

  useEffect(() => {
    if (!location.href.includes(`${rootConfig.app_path}/drive/files`)) {
      setSearch({ target: { value: '' } });
      setCurrentSearch('');
    } else {
      setSearch({ target: { value: searchField ?? '' } });
      setCurrentSearch(searchField ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearch, location]);

  return (
    <StyledContainer
      width="250px"
      height="70%"
      justify="between"
      align="center"
      pad={{ left: 'small', right: '2px' }}
      direction="row"
      margin={{ left: 'xsmall', bottom: '4px' }}
      gap="small"
      open={searchOpened}
    >
      <StyledTextContainer
        open={searchOpened}
        gap="small"
        align="center"
        justify="between"
        pad={{ left: 'small', right: '2px' }}
        direction="row"
      >
        <StyledTextInput
          open={searchOpened}
          placeholder="Cerca nei documenti..."
          size="small"
          value={currentSearch}
          className="search-input"
          plain="full"
          focusIndicator={false}
          onChange={(e: any) => {
            setCurrentSearch(e?.target?.value ?? '');
            lazySearch(e);
          }}
        />
      </StyledTextContainer>
      <StyledSearchIconBox
        background="brand"
        justify="center"
        align="center"
        focusIndicator={false}
        onClick={() => set_searchOpened(p => !p)}
        open={searchOpened}
      >
        <Search color="white" />
      </StyledSearchIconBox>
    </StyledContainer>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  setSearch: (payload: any) => {
    if (
      !location.href.includes(`${rootConfig.app_path}/drive/files`) &&
      payload?.target?.value?.length > 2
    ) {
      navigate(`${rootConfig.app_path}/drive/files`);
    }
    dispatch(setSearchAction(payload?.target?.value));
  },
});

export const MenuSearch = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
