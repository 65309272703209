/* eslint-disable no-param-reassign */
import fetch from 'isomorphic-fetch';
import axios from 'axios';
import { Mutex } from 'async-mutex';
import { rootConfig } from '../config/root-config';
import { getItem, setItem, StorageKeys } from './storage-service';
import rootStore from '../state/root-store';
import { logout, updateUserAction } from '../state/modules/auth';
import { parseJwt } from '../utils/parseJwt';

const httpMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

let isRefreshing = false;

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const login = (data: string) =>
  fetch(`${rootConfig.beEndpoint}/auth/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      service: 'irnerio',
    },
    body: JSON.stringify(data),
  }).then((response: any) => response.json());

const requestMutex = new Mutex();

const refreshHandler = async ({ callback, result, refresh, user }) => {
  let rearm: any = {};
  if ((result.status === 401 || result.status === 403) && refresh) {
    const unlock = await requestMutex.acquire();
    if (!isRefreshing) {
      isRefreshing = true;

      const rearmBody = {
        client_id: rootConfig.sso_client_id,
        grant_type: 'refresh_token',
        scope: 'openid',
        refresh_token: user?.refresh_token,
      };

      const formBody: string[] = [];
      for (const property in rearmBody) {
        if (Object.hasOwn(rearmBody, property)) {
          const encodedKey = encodeURIComponent(property);
          const encodedValue = encodeURIComponent(rearmBody[property]);
          formBody.push(`${encodedKey}=${encodedValue}`);
        }
      }

      const refreshBody = formBody.join('&');

      rearm = await fetch(`${rootConfig.sso_endpoint}/token`, {
        method: httpMethods.POST,
        body: refreshBody,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      }).then(async (response: any) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 204
        ) {
          return response.json();
        }

        const error = await response.json();
        console.log('[refresh error]', error);

        return {
          error: true,
          message: 'unauthorized',
          status: response.status,
          error_description: error.error_description,
        };
      });

      if (rearm.error) {
        result = rearm;
        unlock();
        if (
          rearm.error_description !==
          'Maximum allowed refresh token reuse exceeded'
        ) {
          rootStore.store.dispatch(logout());
        }
      } else {
        const newUser = {
          jwt: rearm?.access_token,
          refresh_token: rearm?.refresh_token,
          id_token: rearm?.id_token,
          ...parseJwt(rearm?.access_token as string),
        };
        delete newUser.id;

        const prevUser = getItem(StorageKeys.USER);
        const updatedUser = { ...prevUser, ...newUser };
        setItem(StorageKeys.USER, updatedUser);
        rootStore.store.dispatch(updateUserAction(updatedUser));

        unlock();

        result = await callback();

        if (result.status === 401 || result.status === 403) {
          rootStore.store.dispatch(logout());
        }
      }

      setTimeout(() => {
        isRefreshing = false;
      }, 3000);
    } else {
      unlock();
      result = await callback();
    }
  }

  return result;
};

const request: any = async ({
  path = '',
  method = httpMethods.GET,
  query = '',
  body,
  headers = {},
  refresh = true,
  noJson = false,
  basePath,
  rootApi = rootConfig.endpointGestionale,
  authType = 'nomox-be',
}: any) => {
  let result: any;
  const user = getItem(StorageKeys.USER);
  const additionalHeaders: any = {};
  additionalHeaders.service = 'irnerio';

  if (authType === 'nomox-be') {
    additionalHeaders.jwt = user?.jwt;
    additionalHeaders.username = user?.username;
  }

  result = await fetch(`${basePath || rootApi}/${path}${query}`, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...additionalHeaders,
      ...headers,
    },
    body: JSON.stringify(body),
  }).then(async (response: any) => {
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 204
    ) {
      return noJson ? response : response.json();
    }

    const err = await response.text();
    const parsedError = JSON.parse(err);

    return {
      error: true,
      message: parsedError.message,
      status: response.status,
      errorBody: parsedError,
    };
  });

  result = await refreshHandler({
    result,
    refresh,
    user,
    callback: async () => {
      if (headers.Authorization) {
        delete headers.Authorization;
      }

      return request({
        path,
        method,
        query,
        body,
        headers,
        refresh: false,
        basePath,
        authType,
        rootApi,
      });
    },
  });

  return noJson ? { data: result } : { ...result };
};

const fileUpload: any = async ({
  path = '',
  method = httpMethods.POST,
  query = '',
  body,
  headers = {},
  refresh = true,
  files,
  rootApi = rootConfig.endpointGestionale,
  filesDataName = 'file',
}: any) => {
  if (!files || files?.length < 1) {
    return {
      error: true,
      message: 'no files',
      status: 400,
    };
  }

  let result: any;
  const user = getItem(StorageKeys.USER);
  const additionalHeaders: any = {};
  additionalHeaders.service = 'irnerio';

  additionalHeaders.jwt = user?.jwt;
  additionalHeaders.username = user?.username;

  const config = {
    headers: {
      'content-type': 'multipart/form-data;charset=utf-8',
      Accept: 'application/json',
      ...additionalHeaders,
      ...headers,
    },
  };
  const formData = new FormData();

  for (let i = 0; i < files?.length || 0; i++) {
    formData.append(filesDataName, files[i]);
  }

  if (body) {
    Object.entries(body).forEach(([key, value]: any) => {
      formData.append(key, value);
    });
  }

  result = await axios
    .post(`${rootApi}/${path}${query}`, formData, config)
    .then(p => p)
    .catch(err => ({
      error: true,
      message: 'error uploading file',
      status: err.statusCode,
    }));

  result = await refreshHandler({
    result,
    refresh,
    user,
    callback: async () => {
      return fileUpload({
        path,
        method,
        query,
        body,
        headers,
        refresh: false,
        files,
      });
    },
  });

  return { ...result };
};

const singleFileUpload: any = async ({
  path = '',
  method = httpMethods.GET,
  query = '',
  body,
  headers = {},
  refresh = true,
  noJson = false,
  basePath,
  rootApi = rootConfig.endpointGestionale,
  authType = 'nomox-be',
}: any) => {
  let result: any;
  const user = getItem(StorageKeys.USER);

  const additionalHeaders: any = {};
  additionalHeaders.service = 'irnerio';

  if (authType === 'nomox-be') {
    additionalHeaders.jwt = user?.jwt;
    additionalHeaders.username = user?.username;
  }

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
      ...additionalHeaders,
      ...headers,
    },
  };
  const formData = new FormData();

  if (body) {
    Object.entries(body).forEach(([key, value]: any) => {
      switch (key) {
        case 'file':
          formData.append('documentiFiles', value);
          break;
        default:
          if (
            (typeof value === 'object' || Array.isArray(value)) &&
            value !== null
          ) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }

          break;
      }
    });
  }

  result = await axios
    .post(`${basePath || rootApi}/${path}${query}`, formData, config)
    .then(p => p)
    .catch(err => ({
      error: true,
      message: 'error uploading file',
      status: err.response.status,
    }));

  result = await refreshHandler({
    result,
    refresh,
    user,
    callback: async () => {
      return singleFileUpload({
        path,
        method,
        query,
        body,
        headers,
        noJson,
        basePath,
        rootApi,
        authType,
        refresh: false,
      });
    },
  });

  return { ...result };
};

const multiFileUpload: any = async ({
  path = '',
  method = httpMethods.GET,
  query = '',
  body,
  headers = {},
  refresh = true,
  noJson = false,
  basePath,
  rootApi = rootConfig.endpointGestionale,
  authType = 'nomox-be',
  fileFields = [],
}: any) => {
  let result: any;
  const user = getItem(StorageKeys.USER);

  const additionalHeaders: any = {};
  additionalHeaders.service = 'irnerio';

  if (authType === 'nomox-be') {
    additionalHeaders.jwt = user?.jwt;
    additionalHeaders.username = user?.username;
  } else {
    additionalHeaders.Authorization = `Bearer ${user?.jwt || '--'}`;
  }

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
      ...additionalHeaders,
      ...headers,
    },
  };
  const formData = new FormData();

  if (body) {
    Object.entries(body).forEach(([key, value]: any) => {
      switch (true) {
        case fileFields.indexOf(key) > -1:
          for (let i = 0; i < value?.length || 0; i++) {
            formData.append(key, value[i]);
          }
          break;
        default:
          if (
            (typeof value === 'object' || Array.isArray(value)) &&
            value !== null
          ) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }

          break;
      }
    });
  }

  result = await axios
    .post(`${basePath || rootApi}/${path}${query}`, formData, config)
    .then(p => p)
    .catch(err => ({
      error: true,
      message: 'error uploading file',
      status: err.response.status,
    }));

  result = await refreshHandler({
    result,
    refresh,
    user,
    callback: async () => {
      return multiFileUpload({
        path,
        method,
        query,
        body,
        headers,
        noJson,
        basePath,
        rootApi,
        authType,
        refresh: false,
      });
    },
  });

  return { ...result };
};

export {
  login,
  request,
  httpMethods,
  fileUpload,
  singleFileUpload,
  multiFileUpload,
};
