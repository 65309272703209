import { ofType } from 'redux-observable';
import { switchMap, EMPTY } from 'rxjs';
import { RESET_FILTERS } from '..';

const epic = (action$: any) =>
  action$.pipe(
    ofType(RESET_FILTERS),
    switchMap(() => EMPTY)
  );

export default epic;
