import * as React from 'react';
import { ReactElement } from 'react';
import { Grommet, Box, Layer } from 'grommet';
import { Menu } from 'grommet-icons';
import styled from 'styled-components';
import { GlobalStyle } from './style';
import { theme } from '../styles/theme';
import { AppHeader } from '../components/app-header/app-header';

interface LayoutProps {
  children: ReactElement[] | ReactElement;
  authState: any;
  isLoggedIn: boolean;
  logout: () => void;
  user: any;
  account: any;
  modalState: any;
  closeModal: () => void;
}

const StyledContent = styled(Box)`
  background-color: white;
  width: 600px;
  padding: 40px 20px 40px 60px;
  border-radius: 30px;
`;

const Layout = ({
  children,
  authState,
  isLoggedIn,
  logout,
  user,
  account,
  modalState,
  closeModal,
}: LayoutProps) => (
  <Grommet theme={theme} cssVars full>
    <GlobalStyle />
    <Box background="white">
      {typeof authState !== 'undefined' && (
        <AppHeader
          user={user}
          isLoggedIn={isLoggedIn}
          appName="Nomox"
          actions={{ logout }}
          appIcon={<Menu />}
          account={account}
        />
      )}
      {/* body */}
      {children}
      {/* body - end */}
      {/* MODAL */}
      {modalState?.open && (
        <Layer
          onEsc={e => {
            e.preventDefault();
            e.stopPropagation();
            closeModal();
            return false;
          }}
          onClickOutside={e => {
            e.preventDefault();
            e.stopPropagation();
            closeModal();
            return false;
          }}
        >
          <StyledContent align="start" justify="center" gap="small">
            <modalState.children />
          </StyledContent>
        </Layer>
      )}
    </Box>
  </Grommet>
);

export default Layout;
