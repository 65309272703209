import { navigate } from '@reach/router';
import {
  LOGIN,
  REGISTER,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  LOGOUT,
  UPDATE_USER,
  LOGOUT_END,
} from './auth.const';
import {
  deleteItem,
  setItem,
  StorageKeys,
} from '../../../services/storage-service';
import { rootConfig } from '../../../config/root-config';

/*
INITIAL STATE
*/

const initialState = {
  isLoggedIn: null,
  currentUser: 0,
  users: [],
  user: null,
  errorMessage: null,
  authLoading: false,
  apiAction: '',
  followers: {
    post: 0,
    follower: 0,
    following: 0,
  },
  isRegistered: false,
  authState: undefined,
};

/*
ACTION HANDLERS
*/

const actionHandlers = {
  [LOGIN](state: any) {
    return { ...state, authLoading: true, errorMessage: null };
  },
  [LOGIN_SUCCESS](state: any, payload: any) {
    setItem(StorageKeys.USER, payload);
    return {
      ...state,
      isLoggedIn: true,
      authState: true,
      authLoading: false,
      errorMessage: null,
      user: payload,
    };
  },
  [LOGIN_ERROR](state: any, payload: string) {
    return {
      ...state,
      isLoggedIn: false,
      authLoading: false,
      errorMessage: payload,
      authState: true,
    };
  },
  [REGISTER](state: any) {
    return { ...state, authLoading: true, errorMessage: null };
  },
  [REGISTER_SUCCESS](state: any, payload: { isRegistered: any }) {
    const { isRegistered } = payload;
    return { ...state, authLoading: false, errorMessage: null, isRegistered };
  },
  [REGISTER_ERROR](state: any, payload: string) {
    return { ...state, authLoading: false, errorMessage: payload };
  },
  [LOGOUT_END](state: any) {
    deleteItem(StorageKeys.USER);
    navigate(rootConfig.app_path);
    return {
      ...state,
      ...initialState,
      isLoggedIn: false,
      authState: true,
    };
  },
  [UPDATE_USER](state: any, payload: any) {
    const user = { ...state.user, ...payload };
    setItem(StorageKeys.USER, user);
    return { ...state, user };
  },
};

/*
REDUCER
*/

const authReducer = (
  state: any = initialState,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;

  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default authReducer;
