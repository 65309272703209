import { ofType } from 'redux-observable';
import {
  catchError,
  concatMap,
  delay,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { concat, EMPTY, from, of, iif, defer } from 'rxjs';
import { DOWNLOAD_DOCUMENTS } from '../../data.const';
import {
  downloadDocument,
  downloadDocuments,
} from '../../../../../services/http/documents.http-service';
import { setLoaders } from '../../data.action';
import { downloadBlob } from '../../../../../utils/downloadBlob';

const epic = (action$: any) =>
  action$.pipe(
    ofType(DOWNLOAD_DOCUMENTS),
    map(({ payload }: any) => payload),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ documents: true })),
        iif(
          () => payload.elements.length > 1 && payload.action !== 'visualizza',
          defer(() => {
            const ids: string[] = payload.elements
              ?.filter((el: any) => el?.id)
              ?.map((el: any) => el.id);
            return from(downloadDocuments(ids)).pipe(
              switchMap(({ data }: any) => data.blob()),
              tap(blob => downloadBlob(blob, 'documenti.zip')),
              switchMap(() => EMPTY),
              catchError(err => {
                console.log('[download multiple documents error]', err);
                return EMPTY;
              })
            );
          }),
          defer(() =>
            from(payload.elements).pipe(
              concatMap((el: any) =>
                from(downloadDocument(el.id)).pipe(
                  switchMap(({ data }: any) => data.blob()),
                  tap(blob => {
                    if (payload.action === 'visualizza') {
                      window.open(URL.createObjectURL(blob));
                    } else {
                      downloadBlob(blob, el.filename);
                    }
                  }),
                  switchMap(() => EMPTY),
                  catchError(err => {
                    console.warn(err);
                    return EMPTY;
                  })
                )
              )
            )
          )
        ),
        of(setLoaders({ documents: false }))
      )
    )
  );

export default epic;
