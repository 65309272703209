import { ofType } from 'redux-observable';
import { catchError, concatMap, delay, map, switchMap } from 'rxjs/operators';
import { concat, defer, EMPTY, from, of } from 'rxjs';
import { setLoaders } from '../../data.action';
import { deleteDocumentDueDiligence } from '../../../../../services/http/documents.http-service';
import {
  DELETE_DOCUMENTS_DUE_DILIGENCE,
  getDocumentsDueDiligence,
} from '../../index';

const epic = (action$: any) =>
  action$.pipe(
    ofType(DELETE_DOCUMENTS_DUE_DILIGENCE),
    map(({ payload }: any) => payload),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ documents: true })),
        defer(() =>
          from(payload.ids).pipe(
            concatMap((id: any) =>
              from(deleteDocumentDueDiligence(id)).pipe(
                switchMap(() => EMPTY),
                catchError(() => EMPTY)
              )
            )
          )
        ),
        of(setLoaders({ documents: false })).pipe(delay(300)),
        of(
          getDocumentsDueDiligence({
            page: payload.page,
            pageSize: payload.pageSize,
          })
        )
      )
    )
  );

export default epic;
