import { ofType } from 'redux-observable';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import { GET_USER_CREDITS } from '../auth.const';
import { httpMethods, request } from '../../../../services/http-service';
import { updateUserAction } from '../auth.action';

const epic = (action$: any) =>
  action$.pipe(
    ofType(GET_USER_CREDITS),
    map(({ payload }: any) => payload),
    switchMap(() =>
      from(request({ path: `user/me/credits`, method: httpMethods.GET })).pipe(
        map((p: any) => p.credits),
        tap(p => console.log(`[epic ${GET_USER_CREDITS}]`, p)),
        switchMap(credits => of(updateUserAction({ credits }))),
        catchError(err => {
          console.error(`[epic ${GET_USER_CREDITS}] error`, err);
          return EMPTY;
        })
      )
    )
    // switchMap(() => EMPTY)
  );

export default epic;
