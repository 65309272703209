import {
  getUserRelatedDataEpic,
  getCategoriesEpic,
  getDocumentsEpic,
  uploadDocumentsEpic,
  deleteCategoryEpic,
  deleteDocumentsEpic,
  downloadDocumentsEpic,
  setFiltersEpic,
  getProjectsEpic,
  createProjectEpic,
  resetFiltersEpic,
  deleteProjectEpic,
  searchDocumentsEpic,
  storeSearchDocumentsEpic,
  modifyDocumentsEpic,
  downloadDocumentsEpicByType,
  getDocumentsDueDiligenceEpic,
  deleteDocumentsDueDiligenceEpic,
  downloadDocumentsDueDiligenceEpic,
  getProjectsDueDiligenceEpic,
  deleteProjectDueDiligenceEpic,
  createProjectDueDiligenceEpic,
  uploadDocumentsDueDiligenceEpic,
  modifyDocumentsDueDiligenceEpic,
} from './epics';
import retrieveAccountEpic from './epics/gestionale/retrieve-account.epic';
import upsertAccountEpic from './epics/gestionale/upsert-account.epic';
import retrieveUserListEpic from './epics/gestionale/retrieve-user-list.epic';
import deleteUserEpic from './epics/gestionale/delete-user.epic';
import retrieveUserEpic from './epics/gestionale/retrieve-user.epic';
import upsertUserEpic from './epics/gestionale/upsert-user.epic';
import retrieveRolesEpic from './epics/gestionale/retrieve-roles.epic';
import retrievePermissionsEpic from './epics/gestionale/retrieve-permissions.epic';
import retrieveAnagraficaListEpic from './epics/gestionale/retrieve-anagrafica-list.epic';
import retrieveNaturaGiuridicaEpic from './epics/gestionale/retrieve-natura-giuridica.epic';
import retrieveAnagraficaEpic from './epics/gestionale/retrieve-anagrafica.epic';
import upsertAnagraficaEpic from './epics/gestionale/upsert-anagrafica.epic';
import retrieveAnagraficaCategoriaEpic from './epics/gestionale/retrieve-anagrafica-categoria.epic';
import retrieveAnagraficaSpecificaEpic from './epics/gestionale/retrieve-anagrafica-specifica.epic';
import deleteAnagraficaEpic from './epics/gestionale/delete-anagrafica.epic';
import retrievePraticaListEpic from './epics/gestionale/retrieve-pratica-list.epic';
import deletePraticaEpic from './epics/gestionale/delete-pratica.epic';
import retrieveIncaricoListEpic from './epics/gestionale/retrieve-incarico-list.epic';
import retrievePraticaMateriaEpic from './epics/gestionale/retrieve-pratica-materia.epic';
import retrievePraticaEpic from './epics/gestionale/retrieve-pratica.epic';
import retrieveDipartimentoListEpic from './epics/gestionale/retrieve-dipartimento-list.epic';
import upsertPraticaEpic from './epics/gestionale/upsert-pratica.epic';
import deleteTimesheetEpic from './epics/gestionale/delete-timesheet.epic';
import retrieveTimesheetEpic from './epics/gestionale/retrieve-timesheet.epic';
import retrieveTimesheetListEpic from './epics/gestionale/retrieve-timesheet-list.epic';
import retrieveTimesheetTipoListEpic from './epics/gestionale/retrieve-timesheet-tipo.epic';
import upsertTimesheetEpic from './epics/gestionale/upsert-timesheet.epic';
import deleteEventoEpic from './epics/gestionale/delete-evento.epic';
import retrieveEventoEpic from './epics/gestionale/retrieve-evento.epic';
import retrieveEventoListEpic from './epics/gestionale/retrieve-evento-list.epic';
import retrieveEventoTipoEpic from './epics/gestionale/retrieve-evento-tipo.epic';
import upsertEventoEpic from './epics/gestionale/upsert-evento.epic';
import deleteIncaricoEpic from './epics/gestionale/delete-incarico.epic';
import retrieveIncaricoEpic from './epics/gestionale/retrieve-incarico.epic';
import upsertIncaricoEpic from './epics/gestionale/upsert-incarico.epic';
import deleteDocumentoEpic from './epics/gestionale/delete-documento.epic';
import retrieveDocumentoEpic from './epics/gestionale/retrieve-documento.epic';
import retrieveDocumentoListEpic from './epics/gestionale/retrieve-documento-list.epic';
import retrieveDocumentoCategoriaListEpic from './epics/gestionale/retrieve-documento-categoria.epic';
import retrieveDocumentoTipoListEpic from './epics/gestionale/retrieve-documento-tipo.epic';
import upsertDocumentoEpic from './epics/gestionale/upsert-documento.epic';
import downloadDocumentoEpic from './epics/gestionale/download-documento.epic';
import uploadDocumentoEpic from './epics/gestionale/upload-documento.epic';
import upsertAnagraficaCategoriaEpic from './epics/gestionale/upsert-anagrafica-categoria.epic';
import deleteAnagraficaCategoriaEpic from './epics/gestionale/delete-anagrafica-categoria.epic';
import upsertDipartimentoEpic from './epics/gestionale/upsert-dipartimento.epic';
import deleteDipartimentoEpic from './epics/gestionale/delete-dipartimento.epic';
import upsertTimesheetTipoEpic from './epics/gestionale/upsert-timesheet-tipo.epic';
import deleteTimesheetTipoEpic from './epics/gestionale/delete-timesheet-tipo.epic';
import upsertDocumentoTipoEpic from './epics/gestionale/upsert-documento-tipo.epic';
import deleteDocumentoTipoEpic from './epics/gestionale/delete-documento-tipo.epic';
import upsertEventoTipoEpic from './epics/gestionale/upsert-evento-tipo.epic';
import deleteEventoTipoEpic from './epics/gestionale/delete-evento-tipo.epic';
import deleteRelazioneTipoEpic from './epics/gestionale/delete-relazione-tipo.epic';
import retrieveRelazioneTipoListEpic from './epics/gestionale/retrieve-relazione-tipo.epic';
import upsertRelazioneTipoEpic from './epics/gestionale/upsert-relazione-tipo.epic';
import deleteGruppoEpic from './epics/gestionale/delete-gruppo.epic';
import retrieveGruppoListEpic from './epics/gestionale/retrieve-gruppo-list.epic';
import upsertGruppoEpic from './epics/gestionale/upsert-gruppo.epic';
import deleteTariffaOrariaEpic from './epics/gestionale/delete-tariffa-oraria.epic';
import retrieveTariffaOrariaListEpic from './epics/gestionale/retrieve-tariffa-oraria-list.epic';
import upsertTariffaOrariaEpic from './epics/gestionale/upsert-tariffa-oraria.epic';
import deleteRoleEpic from './epics/gestionale/delete-role.epic';
import retrieveRoleEpic from './epics/gestionale/retrieve-role.epic';
import upsertRoleEpic from './epics/gestionale/upsert-role.epic';
import deleteOnorarioEpic from './epics/gestionale/delete-onorario.epic';
import deleteRataEpic from './epics/gestionale/delete-rata.epic';
import retrieveRataEpic from './epics/gestionale/retrieve-rata.epic';
import retrieveOnorarioEpic from './epics/gestionale/retrieve-onorario.epic';
import retrieveOnorarioListEpic from './epics/gestionale/retrieve-onorario-list.epic';
import retrieveRataListEpic from './epics/gestionale/retrieve-rata-list.epic';
import upsertOnorarioEpic from './epics/gestionale/upsert-onorario.epic';
import upsertRataEpic from './epics/gestionale/upsert-rata.epic';
import deleteOnorarioIncaricoEpic from './epics/gestionale/delete-onorario-incarico.epic';
import deleteRataIncaricoEpic from './epics/gestionale/delete-rata-incarico.epic';
import retrieveOnorarioIncaricoListEpic from './epics/gestionale/retrieve-onorario-incarico-list.epic';
import retrieveOnorarioIncaricoEpic from './epics/gestionale/retrieve-onorario-incarico.epic';
import retrieveRataIncaricoListEpic from './epics/gestionale/retrieve-rata-incarico-list.epic';
import retrieveRataIncaricoEpic from './epics/gestionale/retrieve-rata-incarico.epic';
import upsertOnorarioIncaricoEpic from './epics/gestionale/upsert-onorario-incarico.epic';
import upsertRataIncaricoEpic from './epics/gestionale/upsert-rata-incarico.epic';
import retrieveGruppoEpic from './epics/gestionale/retrieve-gruppo.epic';
import upsertAnagraficaSpecificaEpic from './epics/gestionale/upsert-anagrafica-specifica.epic';
import deleteSpeseGeneraliEpic from './epics/gestionale/delete-spese-generali.epic';
import retrieveSpeseGeneraliListEpic from './epics/gestionale/retrieve-spese-generali-list.epic';
import upsertSpeseGeneraliEpic from './epics/gestionale/upsert-spese-generali.epic';
import deleteFatturaEpic from './epics/gestionale/delete-fattura.epic';
import retrieveFatturaListEpic from './epics/gestionale/retrieve-fattura-list.epic';
import retrieveFatturaEpic from './epics/gestionale/retrieve-fattura.epic';
import upsertFatturaEpic from './epics/gestionale/upsert-fattura.epic';
import retrieveNotulaListEpic from './epics/gestionale/retrieve-notula-list.epic';
import deleteOnorarioFatturaEpic from './epics/gestionale/delete-onorario-fattura.epic';
import retrieveOnorarioFatturaListEpic from './epics/gestionale/retrieve-onorario-fattura-list.epic';
import retrieveOnorarioFatturaEpic from './epics/gestionale/retrieve-onorario-fattura.epic';
import deleteRataFatturaEpic from './epics/gestionale/delete-rata-fattura.epic';
import retrieveRataFatturaListEpic from './epics/gestionale/retrieve-rata-fattura-list.epic';
import retrieveRataFatturaEpic from './epics/gestionale/retrieve-rata-fattura.epic';
import upsertOnorarioFatturaEpic from './epics/gestionale/upsert-onorario-fattura.epic';
import upsertRataFatturaEpic from './epics/gestionale/upsert-rata-fattura.epic';
import retrieveTimesheetChartListEpic from './epics/gestionale/retrieve-timesheet-chart-list.epic';
import retrieveVisurePricesEpic from './epics/gestionale/retrieve-visure-prices.epic';
import inviaFatturaElettronicaEpic from './epics/gestionale/invia-fattura-elettronica.epic';
import retrieveDepositoEpic from './epics/gestionale/retrieve-deposito.epic';
import retrieveNotificaEpic from './epics/gestionale/retrieve-notifica.epic';
import retrieveConfigurationEpic from './epics/gestionale/retrieve-configuration.epic';
import retrieveDepositoListEpic from './epics/gestionale/retrieve-deposito-list.epic';
import retrieveConfigurationListEpic from './epics/gestionale/retrieve-configuration-list.epic';
import retrieveNotificaListEpic from './epics/gestionale/retrieve-notifica-list.epic';
import upsertConfigurationEpic from './epics/gestionale/upsert-configuration.epic';
import upsertNotificaEpic from './epics/gestionale/upsert-notifica.epic';
import upsertDepositoEpic from './epics/gestionale/upsert-deposito.epic';
import retrieveTimesheetChartListDownloadEpic from './epics/gestionale/retrieve-timesheet-chart-list-download.epic';
import retrieveTimesheetListDownloadEpic from './epics/gestionale/retrieve-timesheet-list-download.epic';

const dataEpics = [
  getUserRelatedDataEpic,
  getCategoriesEpic,
  getDocumentsEpic,
  uploadDocumentsEpic,
  deleteCategoryEpic,
  deleteDocumentsEpic,
  downloadDocumentsEpic,
  downloadDocumentsEpicByType,
  setFiltersEpic,
  getProjectsEpic,
  createProjectEpic,
  resetFiltersEpic,
  deleteProjectEpic,
  searchDocumentsEpic,
  storeSearchDocumentsEpic,
  modifyDocumentsEpic,
  getDocumentsDueDiligenceEpic,
  deleteDocumentsDueDiligenceEpic,
  downloadDocumentsDueDiligenceEpic,
  getProjectsDueDiligenceEpic,
  deleteProjectDueDiligenceEpic,
  createProjectDueDiligenceEpic,
  uploadDocumentsDueDiligenceEpic,
  modifyDocumentsDueDiligenceEpic,
  retrieveAccountEpic,
  upsertAccountEpic,
  retrieveUserListEpic,
  deleteUserEpic,
  retrieveUserEpic,
  upsertUserEpic,
  retrieveRolesEpic,
  retrievePermissionsEpic,
  retrieveAnagraficaListEpic,
  retrieveNaturaGiuridicaEpic,
  retrieveAnagraficaEpic,
  upsertAnagraficaEpic,
  retrieveAnagraficaCategoriaEpic,
  retrieveAnagraficaSpecificaEpic,
  deleteAnagraficaEpic,
  retrievePraticaListEpic,
  deletePraticaEpic,
  retrieveIncaricoListEpic,
  retrievePraticaMateriaEpic,
  retrievePraticaEpic,
  retrieveDipartimentoListEpic,
  upsertPraticaEpic,
  deleteTimesheetEpic,
  retrieveTimesheetEpic,
  retrieveTimesheetListEpic,
  retrieveTimesheetTipoListEpic,
  upsertTimesheetEpic,
  deleteEventoEpic,
  retrieveEventoEpic,
  retrieveEventoListEpic,
  retrieveEventoTipoEpic,
  upsertEventoEpic,
  deleteIncaricoEpic,
  retrieveIncaricoEpic,
  upsertIncaricoEpic,
  deleteDocumentoEpic,
  retrieveDocumentoEpic,
  retrieveDocumentoListEpic,
  retrieveDocumentoCategoriaListEpic,
  retrieveDocumentoTipoListEpic,
  upsertDocumentoEpic,
  downloadDocumentoEpic,
  uploadDocumentoEpic,
  upsertAnagraficaCategoriaEpic,
  deleteAnagraficaCategoriaEpic,
  upsertDipartimentoEpic,
  deleteDipartimentoEpic,
  upsertTimesheetTipoEpic,
  deleteTimesheetTipoEpic,
  upsertDocumentoTipoEpic,
  deleteDocumentoTipoEpic,
  upsertEventoTipoEpic,
  deleteEventoTipoEpic,
  deleteRelazioneTipoEpic,
  retrieveRelazioneTipoListEpic,
  upsertRelazioneTipoEpic,
  deleteGruppoEpic,
  retrieveGruppoListEpic,
  upsertGruppoEpic,
  deleteTariffaOrariaEpic,
  retrieveTariffaOrariaListEpic,
  upsertTariffaOrariaEpic,
  deleteRoleEpic,
  retrieveRoleEpic,
  upsertRoleEpic,
  deleteOnorarioEpic,
  deleteRataEpic,
  retrieveRataEpic,
  retrieveOnorarioEpic,
  retrieveOnorarioListEpic,
  retrieveRataListEpic,
  upsertOnorarioEpic,
  upsertRataEpic,
  deleteOnorarioIncaricoEpic,
  deleteRataIncaricoEpic,
  retrieveOnorarioIncaricoListEpic,
  retrieveOnorarioIncaricoEpic,
  retrieveRataIncaricoListEpic,
  retrieveRataIncaricoEpic,
  upsertOnorarioIncaricoEpic,
  upsertRataIncaricoEpic,
  retrieveGruppoEpic,
  upsertAnagraficaSpecificaEpic,
  deleteSpeseGeneraliEpic,
  retrieveSpeseGeneraliListEpic,
  upsertSpeseGeneraliEpic,
  deleteFatturaEpic,
  retrieveFatturaListEpic,
  retrieveFatturaEpic,
  upsertFatturaEpic,
  retrieveNotulaListEpic,
  deleteOnorarioFatturaEpic,
  retrieveOnorarioFatturaListEpic,
  retrieveOnorarioFatturaEpic,
  deleteRataFatturaEpic,
  retrieveRataFatturaListEpic,
  retrieveRataFatturaEpic,
  upsertOnorarioFatturaEpic,
  upsertRataFatturaEpic,
  retrieveTimesheetChartListEpic,
  inviaFatturaElettronicaEpic,
  retrieveVisurePricesEpic,
  retrieveDepositoEpic,
  retrieveNotificaEpic,
  retrieveConfigurationEpic,
  retrieveDepositoListEpic,
  retrieveConfigurationListEpic,
  retrieveNotificaListEpic,
  upsertConfigurationEpic,
  upsertNotificaEpic,
  upsertDepositoEpic,
  retrieveTimesheetChartListDownloadEpic,
  retrieveTimesheetListDownloadEpic,
];

export default dataEpics;
