import { rootConfig } from '../../config/root-config';
import { httpMethods, request } from '../http-service';

const base = 'tags';

const getProjectsList = (query: string) =>
  request({ path: `${base}`, method: httpMethods.GET, query });

const getProjectsDueDiligenceList = (query: string) =>
  request({
    path: `${base}`,
    method: httpMethods.GET,
    query,
    rootApi: rootConfig.urlDueDiligence,
  });

const deleteProject = (id: string) =>
  request({ path: `${base}/${id}`, method: httpMethods.DELETE });

const deleteProjectDueDiligence = (id: string) =>
  request({
    path: `${base}/${id}`,
    method: httpMethods.DELETE,
    rootApi: rootConfig.urlDueDiligence,
  });

const createProject = (body: any) =>
  request({ path: `${base}`, method: httpMethods.POST, body });

const createProjectDueDiligence = (body: any) =>
  request({
    path: `${base}`,
    method: httpMethods.POST,
    body,
    rootApi: rootConfig.urlDueDiligence,
  });

export {
  getProjectsList,
  deleteProject,
  createProject,
  getProjectsDueDiligenceList,
  deleteProjectDueDiligence,
  createProjectDueDiligence,
};
