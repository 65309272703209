import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_RATA_LIST } from '../../data.const';
import {
  setListConfigAction,
  setLoaders,
  setPopupInfo,
  storeRataListAction,
} from '../../data.action';
import { filler } from '../../../../../utils/utils';

const retrieveRataListEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_RATA_LIST),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_RATA_LIST}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ onorarioLoader: true })),
        of(setListConfigAction({ rata: body })),
        defer(() =>
          from(
            request({ path: `rata/list`, method: httpMethods.POST, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return { total: p?.total, result: [...p?.result, ...filler] };
            }),
            // tap(p => console.log(`[epic ${RETRIEVE_RATA_LIST}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(data => of(storeRataListAction(data))),
            catchError(err => {
              console.error(`[epic ${RETRIEVE_RATA_LIST}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ onorarioLoader: false }))
      );
    })
  );

export default retrieveRataListEpic;
