import { ofType } from 'redux-observable';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { concat, EMPTY, from, of, timer } from 'rxjs';
import { navigate } from '@reach/router';
import { UPLOAD_DOCUMENTS_DUE_DILIGENCE } from '../../data.const';
import { setLoaders, setPopupInfo } from '../../data.action';
import { uploadDocumentsDueDiligence } from '../../../../../services/http/documents.http-service';
import { rootConfig } from '../../../../../config/root-config';
import { getDocumentsDueDiligence } from '../../index';

const getBase64 = (file: any) =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve((reader.result as string).split('base64,')[1]);
    };
  });

const epic = (action$: any) =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENTS_DUE_DILIGENCE),
    map(({ payload }: any) => payload),
    switchMap((p: any) => {
      const files = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < p?.files?.length || 0; i++) {
        files.push(p.files[i]);
      }
      return from(
        Promise.all(
          files?.map(async (file: any) => {
            const base64 = await getBase64(file);
            return {
              name: file.name,
              tagid: p?.progetto?.id || undefined,
              networks: p?.networks || ['rete1'],
              base64,
            };
          })
        )
      );
    }),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ uploadDocuments: true })),
        from(uploadDocumentsDueDiligence({ docs: payload })).pipe(
          // switchMap((documents: any) =>
          //   from(
          //     Promise.all(
          //       documents?.data?.results?.map(async (document: any) => {
          //         if (document?.id && payload?.categoria?.id) {
          //           await setCategory({
          //             id: document?.id,
          //             body: { id: payload.categoria.id },
          //           });
          //         }

          //         if (document?.id && payload?.progetto?.id) {
          //           await setProject({
          //             id: document?.id,
          //             body: { id: payload.progetto.id },
          //           });
          //         }
          //       })
          //     )
          //   )
          // ),
          switchMap(() => EMPTY),
          catchError(err => {
            console.log('[files upload error]', err);
            return EMPTY;
          })
        ),
        of(getDocumentsDueDiligence({ page: 1, pageSize: 10 })),
        of(setLoaders({ uploadDocuments: false })).pipe(
          delay(300),
          tap(() => navigate(`${rootConfig.app_path}/due-diligence/documenti`))
        ),
        of(
          setPopupInfo({
            opened: true,
            message: 'Documento caricato con successo.',
          })
        ),
        timer(3000).pipe(
          switchMap(() =>
            of(
              setPopupInfo({
                opened: false,
                message: '',
              })
            )
          )
        )
      )
    )
  );

export default epic;
