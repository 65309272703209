import { grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { SortableIcon } from './SortableIcon';

export const theme = deepMerge(grommet, {
  global: {
    colors: {
      active: 'light-5',
      placeholder: 'dark-1',
      error: '#D8222A',
      greenLeft: '#0E5E36',
      greenRight: '#39AA48',
      brand: '#009849',
      lightGreen: '#A6E1C9',
      white: '#ffffff',
      darkGray: '#8A8B8A',
      borderGreen: '#009849',
      menuBg: '#343434',
      menuText: '#B4B5B4',
      dotMenu: '#EBEBEB',
      headText2: '#8CC63F',
      searchBar: '#4D4D4D',
      searchBarText: '#B4B5B4',
      pageHead: '#B4B5B4',
      textColor: '#818284',
      textSecondary: '#E7E7E8',
      orange: '#F1AA47',
      headText: '#8CC63F',
      inputLabel: '#2B2C2B',
      statusSuccess: '#3FCE2F',
      yellowOne: '#FCEC14',
      selectedRow: '#D8ECD6',
      sharing: '#FFA06F',
    },
    elevation: {
      light: {
        small: '0 0 1px 0 rgba(0, 0, 0, 0.40), 0 1px 2px 0 rgba(0,0,0,0.40)',
        medium: '0 0 2px 0 rgba(0,0,0,0.40), 0 2px 4px 0 rgba(0,0,0,0.40)',
        large: '0 0 1px 0 rgba(0,0,0,0.40), 0 4px 8px 0 rgba(0,0,0,0.40)',
        xlarge: '0 0 1px 0 rgba(0,0,0,0.40), 0 8px 16px 0 rgba(0,0,0,0.40)',
      },
    },
    font: {
      size: '16px',
      height: '20px',
    },
    input: {
      weight: 400,
    },
    size: {
      avatar: '36px',
      sidebar: '60px',
    },
  },
  icon: {
    size: {
      medium: '18px',
    },
  },
  paragraph: {
    medium: {
      size: '16px',
      height: '20px',
    },
    large: {
      size: '20px',
      height: '24px',
    },
  },
  accordion: {
    border: {
      side: 'bottom',
      size: '0px',
    },
    panel: {
      border: {
        style: 'hidden',
      },
    },
  },
  text: {
    xsmall: {
      size: '14px',
    },
    small: {
      size: '16px',
    },
    medium: {
      size: '18px',
    },
    large: {
      size: '22px',
    },
  },
  button: {
    color: 'brand',
    primary: {
      color: 'white',
    },
    disabled: {
      opacity: 0.8,
    },
  },
  textInput: {
    disabled: {
      opacity: 0.8,
    },
  },
  dataTable: {
    header: {
      font: {
        size: 'small',
      },
      color: 'text-strong',
      extend: ({ column, sort, sortable }: any) => `
          ${
            sortable &&
            sort &&
            sort.property !== column &&
            `
              :hover {
                svg {
                  opacity: 100%;
                }
              }
            `
          }
         `,
    },
    icons: {
      sortable: SortableIcon,
    },
  },
  layer: {
    border: {
      radius: 'medium',
      intelligentRounding: true,
    },
  },
  select: {
    options: { text: { size: 'small' } },
    clear: { text: { size: 'small' } },
  },
  radioButton: {
    size: '12px',
    container: {
      extend: `align-items: start;`,
    },
  },
});
