import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, concat, defer, debounceTime } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_TIMESHEET_LIST_DOWNLOAD } from '../../data.const';
import { downloadBlob } from '../../../../../utils/downloadBlob';

const retrieveTimesheetListDownloadEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_TIMESHEET_LIST_DOWNLOAD),
    debounceTime(20),
    map(({ payload }: any) => payload),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        defer(() =>
          from(
            request({
              path: `timesheet/export`,
              method: httpMethods.POST,
              body,
              noJson: true,
            })
          ).pipe(
            switchMap(({ data }: any) => data.blob()),
            tap((blob: any) => {
              let extension = '';
              if (
                blob?.type?.includes(
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                )
              ) {
                extension = '.xlsx';
              }
              downloadBlob(blob, `Timesheet${extension}`);
            }),
            switchMap(() => EMPTY),
            catchError(err => {
              console.error(
                `[epic ${RETRIEVE_TIMESHEET_LIST_DOWNLOAD}] error`,
                err
              );
              return EMPTY;
            })
          )
        )
      );
    })
  );

export default retrieveTimesheetListDownloadEpic;
