import { ofType } from 'redux-observable';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { concat, defer, EMPTY, from, of } from 'rxjs';
import { DELETE_PROJECT, getProjects, setLoaders } from '../../index';
import { deleteProject } from '../../../../../services/http/progetti.http-service';

const epic = (action$: any) =>
  action$.pipe(
    ofType(DELETE_PROJECT),
    map(({ payload }: any) => payload),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ categories: true })),
        defer(() =>
          from(deleteProject(payload)).pipe(
            switchMap(() => of(getProjects(null))),
            catchError(err => {
              console.log('DELETE_PROJECT error', err);
              return of(getProjects(null));
            })
          )
        ),
        of(setLoaders({ categories: false })).pipe(delay(300))
      )
    )
  );

export default epic;
