import { rootConfig } from '../../config/root-config';
import { fileUpload, httpMethods, request } from '../http-service';

const base = 'documents';

const getDocumentsList = (query: string, isSearch = false) =>
  request({
    path: `${isSearch ? 'search' : base}`,
    method: httpMethods.GET,
    query,
  });

const getDocumentsDueDiligenceList = (query: string, isSearch = false) =>
  request({
    path: `${isSearch ? 'search' : base}`,
    method: httpMethods.GET,
    query,
    rootApi: rootConfig.urlDueDiligence,
  });

const getDocumentStatus = (id: string) =>
  request({ path: `${base}/${id}/status`, method: httpMethods.GET });

const getDocumentDueDiligenceStatus = (id: string) =>
  request({
    path: `${base}/${id}/status`,
    method: httpMethods.GET,
    rootApi: rootConfig.urlDueDiligence,
  });

const uploadDocuments = ({ files, body }: any) =>
  fileUpload({ path: `${base}`, method: httpMethods.POST, files, body });

const uploadDocumentsDueDiligence = (body: any) =>
  request({
    path: `${base}`,
    method: httpMethods.POST,
    body,
    rootApi: rootConfig.urlDueDiligence,
  });

const setCategory = ({ id, body }: any) =>
  request({ path: `${base}/${id}/category`, method: httpMethods.PUT, body });

const setProject = ({ id, body }: any) =>
  request({ path: `${base}/${id}/tag`, method: httpMethods.PUT, body });

const deleteDocument = (id: string) =>
  request({ path: `${base}/${id}`, method: httpMethods.DELETE });

const deleteDocumentDueDiligence = (id: string) =>
  request({
    path: `${base}/${id}`,
    method: httpMethods.DELETE,
    rootApi: rootConfig.urlDueDiligence,
  });

const modifyDocument = ({ id, body }: any) =>
  request({ path: `${base}/${id}`, method: httpMethods.PUT, body });

const modifyDocumentDueDiligence = ({ id, body }: any) =>
  request({
    path: `${base}/${id}`,
    method: httpMethods.PUT,
    body,
    rootApi: rootConfig.urlDueDiligence,
  });

const downloadDocuments = (ids: string[]) => {
  let query = `?`;
  ids?.forEach((el: string, index: number) => {
    query += index === 0 ? `id=${el}` : `&id=${el}`;
  });
  return request({
    path: `${base}/download`,
    method: httpMethods.GET,
    query,
    noJson: true,
  });
};

const downloadDocument = (id: string) =>
  request({
    path: `${base}/${id}/view?download=true`,
    method: httpMethods.GET,
    noJson: true,
  });

const downloadDocumentDueDiligence = (id: string) =>
  request({
    path: `${base}/${id}/view`,
    method: httpMethods.GET,
    noJson: true,
    rootApi: rootConfig.urlDueDiligence,
  });

const downloadDocumentsByType = ({ type, id }: any) =>
  request({
    path: `${type}/${id}/download`,
    method: httpMethods.GET,
    noJson: true,
  });

export {
  getDocumentsList,
  getDocumentStatus,
  uploadDocuments,
  deleteDocument,
  downloadDocuments,
  downloadDocument,
  setCategory,
  setProject,
  modifyDocument,
  downloadDocumentsByType,
  getDocumentsDueDiligenceList,
  getDocumentDueDiligenceStatus,
  deleteDocumentDueDiligence,
  downloadDocumentDueDiligence,
  uploadDocumentsDueDiligence,
  modifyDocumentDueDiligence,
};
