import { ofType } from 'redux-observable';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { UPSERT_ONORARIO_FATTURA } from '../../data.const';
import {
  retrieveOnorarioFatturaListAction,
  setCurrentOnorarioFatturaAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';
import { selectOnorarioFatturaList } from '../../data.selector';

const upsertOnorarioFatturaEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_ONORARIO_FATTURA),
    tap(p => console.log(`[epic ${UPSERT_ONORARIO_FATTURA}]`, p)),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, ...rest } = payload?.body;
      if (rest.id === 'crea') {
        delete rest.id;
      }
      return { info: payload?.info, body: rest };
    }),
    tap(p => console.log(`[epic ${UPSERT_ONORARIO_FATTURA}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, info }, state]) => {
      let currentOnorariPersonalizzatiId = selectOnorarioFatturaList(state);
      if (currentOnorariPersonalizzatiId?.result) {
        currentOnorariPersonalizzatiId =
          currentOnorariPersonalizzatiId?.result?.reduce(
            (previous: any, current: any) => {
              if (!current?.id) {
                return previous;
              }
              return [...previous, current.id];
            },
            []
          );
      } else {
        currentOnorariPersonalizzatiId = [];
      }

      return concat(
        of(setLoaders({ fatturaLoader: true, page: true })),
        defer(() =>
          from(
            request({
              path: `onorarioFattura`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            switchMap(data => {
              return concat(
                // of(setCurrentOnorarioFatturaAction(data)),
                of(null).pipe(
                  delay(500),
                  switchMap(() =>
                    concat(
                      of(
                        setPopupInfo({
                          opened: true,
                          message: 'Operazione completata con successo',
                          timer: 3000,
                        })
                      ),
                      of(
                        retrieveOnorarioFatturaListAction({
                          pageSize: rootConfig.populateFields,
                          page: 1,
                          rateFirst: false,
                          order: [['descrizione', 'asc']],
                          filters: {
                            or: [
                              {
                                field: 'id',
                                value: [
                                  ...currentOnorariPersonalizzatiId,
                                  data?.id,
                                ],
                                operator: 'and',
                                type: 'in',
                              },
                            ],
                          },
                        })
                      ),
                      of(null).pipe(
                        tap(() => {
                          info?.action && info?.action();
                        }),
                        switchMap(() => EMPTY)
                      )
                    )
                  )
                )
              );
            }),
            catchError(err => {
              console.error(`[epic ${UPSERT_ONORARIO_FATTURA}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Operazione non riuscita',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ fatturaLoader: false, page: false }))
      );
    })
  );

export default upsertOnorarioFatturaEpic;
