import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_PEMRMISSIONS } from '../../data.const';
import {
  setListConfigAction,
  setLoaders,
  setPopupInfo,
  storePermissionsAction,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const retrievePermissionsEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_PEMRMISSIONS),
    map(() => ({
      page: 1,
      pageSize: rootConfig.populateFields,
      order: [['id', 'asc']],
    })),
    // tap(p => console.log(`[epic ${RETRIEVE_PEMRMISSIONS}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ roleLoader: true })),
        of(setListConfigAction({ role: body })),
        defer(() =>
          from(
            request({
              path: `role/permission/list`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return { total: p?.total, result: [...p?.result] };
            }),
            // tap(p => console.log(`[epic ${RETRIEVE_PEMRMISSIONS}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(data => of(storePermissionsAction(data))),
            catchError(err => {
              console.error(`[epic ${RETRIEVE_PEMRMISSIONS}] error`, err);
              return EMPTY;
              // return of(
              //   setPopupInfo({
              //     opened: true,
              //     message: 'Errore nel recupero delle informazioni',
              //     type: 'BAD',
              //     timer: 3000,
              //   })
              // );
            })
          )
        ),
        of(setLoaders({ roleLoader: false }))
      );
    })
  );

export default retrievePermissionsEpic;
