import * as React from 'react';
import { Box, Text, Image } from 'grommet';
import { useCallback } from 'react';
import { navigate } from '@reach/router';
import { rootConfig } from '../../config/root-config';
import LogoIrnerio from '../../images/irnerio-white-def.png';

export const AppLogo = ({}: any) => {
  const click = useCallback(() => {
    navigate(`${rootConfig.app_path}/drive/home`);
  }, []);

  return (
    <Box
      id="logo"
      background="linear-gradient(90deg, rgba(14,94,54,1) 0%, rgba(57,170,72,1) 100%)"
      width="300px"
      height="100%"
      justify="end"
      align="center"
      pad={{ bottom: '5px' }}
      margin={{ right: 'medium' }}
      focusIndicator={false}
      onClick={click}
    >
      <Box width="100px">
        <Image fit="contain" src={LogoIrnerio} style={{ maxHeight: 31 }} />
      </Box>
    </Box>
  );
};
