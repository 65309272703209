exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-completa-tsx": () => import("./../../../src/pages/completa.tsx" /* webpackChunkName: "component---src-pages-completa-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reg-user-tsx": () => import("./../../../src/pages/reg_user.tsx" /* webpackChunkName: "component---src-pages-reg-user-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset_password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-switch-account-tsx": () => import("./../../../src/pages/switch_account.tsx" /* webpackChunkName: "component---src-pages-switch-account-tsx" */)
}

