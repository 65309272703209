import { ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs';
import { SEARCH_DOCUMENTS } from '../../data.const';
import { storeSearchDocumentsAction } from '../../data.action';

const epic = (action$: any) =>
  action$.pipe(
    ofType(SEARCH_DOCUMENTS),
    debounceTime(300),
    map((action: any) => action?.payload?.target?.value),
    map(text => storeSearchDocumentsAction(text))
  );

export default epic;
