import { ofType } from 'redux-observable';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, iif } from 'rxjs';
import { navigate } from '@reach/router';
import {
  httpMethods,
  singleFileUpload,
} from '../../../../../services/http-service';
import { UPSERT_INCARICO } from '../../data.const';
import {
  retrieveIncaricoListAction,
  setCurrentIncaricoAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const upsertIncaricoEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_INCARICO),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, listBody, ...rest } = payload;
      if (rest.id === 'crea') {
        delete rest.id;
      }
      return { body: rest, listBody };
    }),
    tap(p => console.log(`[epic ${UPSERT_INCARICO}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, listBody }, state]) => {
      return concat(
        of(setLoaders({ incaricoLoader: true, page: true })),
        defer(() =>
          from(
            singleFileUpload({
              path: `incarico`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.data?.result;
            }),
            switchMap(data =>
              concat(
                of(setCurrentIncaricoAction(data)),
                iif(
                  () => listBody,
                  of(retrieveIncaricoListAction(listBody)),
                  EMPTY
                ),
                of(null).pipe(
                  tap(() => {
                    if (body?.id) {
                      return; // do not navigate in case of edit
                    }
                    navigate(
                      `${rootConfig.app_path}/gestionale/incarichi-edit/${
                        data?.id || 'crea'
                      }`
                    ).then(() => null);
                  }),
                  delay(500),
                  switchMap(() =>
                    of(
                      setPopupInfo({
                        opened: true,
                        message: 'Operazione completata con successo',
                        timer: 3000,
                      })
                    )
                  )
                )
              )
            ),
            catchError(err => {
              console.error(`[epic ${UPSERT_INCARICO}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Operazione non riuscita',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ incaricoLoader: false, page: false }))
      );
    })
  );

export default upsertIncaricoEpic;
