import { connect } from 'react-redux';
import Layout from './layout';
import {
  getAuthState,
  getUser,
  isLoggedIn,
  logout,
} from '../state/modules/auth';
import {
  selectAccount,
  selectModalState,
  setModalStateAction,
} from '../state/modules/data';

const mapStateToProps = (state: any) => ({
  authState: getAuthState(state),
  isLoggedIn: isLoggedIn(state),
  user: getUser(state),
  account: selectAccount(state),
  modalState: selectModalState(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(logout()),
  closeModal: () => dispatch(setModalStateAction({ open: false })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
