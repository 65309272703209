import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import axios from 'axios';
import { INVIA_FATTURA_ELETTRONICA } from '../../data.const';
import {
  setLoaders,
  setPopupInfo,
  storeAccountAction,
} from '../../data.action';
import { httpMethods, request } from '../../../../../services/http-service';
import { getUserCreditsAction } from '../../../auth';

const inviaFatturaElettronicaEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(INVIA_FATTURA_ELETTRONICA),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, ...rest } = payload;
      return rest;
    }),
    tap(p => console.log(`[epic ${INVIA_FATTURA_ELETTRONICA}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, state]) => {
      return concat(
        of(setLoaders({ fattura: true, page: true })),
        defer(() =>
          from(
            // axios.post(
            //   'https://marche.fosforo.app/ws/wssdi.asmx/consegna_xml',
            //   body
            // )
            request({
              path: `openapi/send-invoice`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            tap(p =>
              console.log(`[epic ${INVIA_FATTURA_ELETTRONICA} response]`, p)
            ),
            switchMap(data =>
              of(
                storeAccountAction(data),
                getUserCreditsAction(),
                setPopupInfo({
                  opened: true,
                  message: 'Invio completato',
                  timer: 3000,
                })
              )
            ),
            catchError(err => {
              console.error(`[epic ${INVIA_FATTURA_ELETTRONICA}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Operazione non riuscita',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ fattura: false, page: false }))
      );
    })
  );

export default inviaFatturaElettronicaEpic;
