import { ofType } from 'redux-observable';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, iif, debounceTime } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_DEPOSITO_LIST } from '../../data.const';
import {
  setListConfigAction,
  setLoaders,
  setPopupInfo,
  storeDepositoListAction,
} from '../../data.action';
import { filler } from '../../../../../utils/utils';
import { selectDepositoList } from '../../data.selector';

const retrieveDepositoListEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_DEPOSITO_LIST),
    debounceTime(20),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_DEPOSITO_LIST}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, state]) => {
      return concat(
        of(setLoaders({ depositoLoader: true })),
        iif(
          () => body?.pageSize <= 100,
          of(setListConfigAction({ deposito: body })),
          EMPTY
        ),
        defer(() =>
          from(
            request({
              path: `deposito/list`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }

              // lazy load
              if (body?.append) {
                const currentList = selectDepositoList(state)?.result;
                return {
                  total: p?.total,
                  result: [...(currentList ?? []), ...p?.result],
                };
              }

              return {
                total: p?.total,
                result: [...p?.result, ...filler],
              };
            }),
            // tap(p => console.log(`[epic ${RETRIEVE_DEPOSITO_LIST}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(data => of(storeDepositoListAction(data))),
            catchError(err => {
              console.error(`[epic ${RETRIEVE_DEPOSITO_LIST}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ depositoLoader: false }))
      );
    })
  );

export default retrieveDepositoListEpic;
