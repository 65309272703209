import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { request } from '../../../../../services/http-service';
import { RETRIEVE_VISURE_PRICES } from '../../data.const';
import { setLoaders, storeVisurePricesAction } from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const retrieveVisurePricesEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_VISURE_PRICES),
    switchMap(() => {
      return concat(
        of(setLoaders({ documento: true })),
        defer(() =>
          from(
            request({
              path: 'get-visure-prices',
              basePath: rootConfig.endpointWpGestionale,
              authType: 'nomox-wp',
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p?.data;
            }),
            // tap(p => console.log(`[epic ${RETRIEVE_VISURE_PRICES}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(data => of(storeVisurePricesAction(data))),
            catchError(err => {
              console.error(`[epic ${RETRIEVE_VISURE_PRICES}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ documento: false }))
      );
    })
  );

export default retrieveVisurePricesEpic;
