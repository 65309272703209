import { ofType } from 'redux-observable';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { concat, defer, EMPTY, from, of } from 'rxjs';
import {
  DELETE_PROJECT_DUE_DILIGENCE,
  getProjectsDueDiligence,
  setLoaders,
} from '../../index';
import { deleteProjectDueDiligence } from '../../../../../services/http/progetti.http-service';

const epic = (action$: any) =>
  action$.pipe(
    ofType(DELETE_PROJECT_DUE_DILIGENCE),
    map(({ payload }: any) => payload),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ categories: true })),
        defer(() =>
          from(deleteProjectDueDiligence(payload)).pipe(
            switchMap(() => of(getProjectsDueDiligence(null))),
            catchError(err => {
              console.log('DELETE_PROJECT error', err);
              return of(getProjectsDueDiligence(null));
            })
          )
        ),
        of(setLoaders({ categories: false })).pipe(delay(300))
      )
    )
  );

export default epic;
