import { ofType } from 'redux-observable';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { concat, defer, EMPTY, from, of, timer } from 'rxjs';
import { getDocuments, setLoaders, setPopupInfo } from '../../data.action';
import { MODIFY_DOCUMENTS } from '../../index';
import { modifyDocument } from '../../../../../services/http/documents.http-service';

const epic = (action$: any) =>
  action$.pipe(
    ofType(MODIFY_DOCUMENTS),
    map(({ payload }: any) => payload),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ uploadDocuments: true })),
        defer(() =>
          from(payload.documents).pipe(
            concatMap((p: any) =>
              from(
                modifyDocument({
                  id: p?.id,
                  body: {
                    category: payload?.categoria?.id,
                    tag: payload?.progetto?.id,
                  },
                })
              ).pipe(
                switchMap(() => EMPTY),
                catchError(() => EMPTY)
              )
            )
          )
        ),
        of(getDocuments({ page: payload?.page, pageSize: payload?.pageSize })),
        of(setLoaders({ uploadDocuments: false })),
        of(
          setPopupInfo({
            opened: true,
            message: 'Aggiornamento completato con successo.',
          })
        ),
        timer(3000).pipe(
          switchMap(() =>
            of(
              setPopupInfo({
                opened: false,
                message: '',
              })
            )
          )
        )
      )
    )
  );

export default epic;
