export const STATE_KEY = 'auth';

export const LOGIN = `${STATE_KEY}/LOGIN`;
export const LOGIN_SUCCESS = `${STATE_KEY}/LOGIN_SUCCESS`;
export const LOGIN_ERROR = `${STATE_KEY}/LOGIN_ERROR`;
export const REGISTER = `${STATE_KEY}/REGISTER`;
export const REGISTER_SUCCESS = `${STATE_KEY}/REGISTER_SUCCESS`;
export const REGISTER_ERROR = `${STATE_KEY}/REGISTER_ERROR`;
export const LOGOUT = `${STATE_KEY}/LOGOUT`;
export const LOGOUT_END = `${STATE_KEY}/LOGOUT_END`;
export const RETRIEVE_AUTH_STATE = `${STATE_KEY}/RETRIEVE_AUTH_STATE`;
export const UPDATE_USER = `${STATE_KEY}/UPDATE_USER`;
export const GET_USER_CREDITS = `${STATE_KEY}/GET_USER_CREDITS`;
export const GET_USER_DATA = `${STATE_KEY}/GET_USER_DATA`;
