import { STATE_KEY } from './auth.const';

const mainSelector = (state: any) => state[STATE_KEY];

export const isLoggedIn = (state: any) => {
  return mainSelector(state).isLoggedIn;
};
export const authLoading = (state: any) => mainSelector(state).authLoading;
export const getErrorMessage = (state: any) => mainSelector(state).errorMessage;
export const getUser = (state: any) => mainSelector(state).user;
export const getAuthState = (state: any) => mainSelector(state).authState;
