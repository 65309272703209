import { ofType } from 'redux-observable';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, timer } from 'rxjs';
import { navigate } from '@reach/router';
import { httpMethods, request } from '../../../../../services/http-service';
import { UPSERT_ONORARIO } from '../../data.const';
import {
  setCurrentOnorarioAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const upsertOnorarioEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_ONORARIO),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, ...rest } = payload;
      if (rest.id === 'crea') {
        delete rest.id;
      }
      return rest;
    }),
    tap(p => console.log(`[epic ${UPSERT_ONORARIO}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, state]) => {
      return concat(
        of(setLoaders({ onorarioLoader: true, page: true })),
        defer(() =>
          from(
            request({ path: `onorario`, method: httpMethods.POST, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            switchMap(data =>
              concat(
                of(setCurrentOnorarioAction(data)),
                of(null).pipe(
                  tap(() => {
                    if (body?.id) {
                      return; // do not navigate in case of edit
                    }
                    navigate(
                      `${rootConfig.app_path}/account/onorari-edit/${
                        data?.id || 'crea'
                      }`
                    ).then(() => null);
                  }),
                  delay(500),
                  switchMap(() =>
                    of(
                      setPopupInfo({
                        opened: true,
                        message: 'Operazione completata con successo',
                        timer: 3000,
                      })
                    )
                  )
                )
              )
            ),
            catchError(err => {
              console.error(`[epic ${UPSERT_ONORARIO}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Operazione non riuscita',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ onorarioLoader: false, page: false }))
      );
    })
  );

export default upsertOnorarioEpic;
