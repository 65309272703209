import * as querystring from 'querystring';
import { EMPTY, concat, from, of, timer } from 'rxjs';
import { ofType } from 'redux-observable';
import moment from 'moment';
import {
  catchError,
  delay,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { POLL_DOCUMENTS_STATUS } from '../../data.const';
import {
  getDocumentsDueDiligenceList,
  getDocumentDueDiligenceStatus,
} from '../../../../../services/http/documents.http-service';
import { pollDocumentStatus, setLoaders } from '../../data.action';
import {
  getDocumentsDueDiligenceSuccess,
  GET_DOCUMENTS_DUE_DILIGENCE,
  selectDocumentiDueDiligence,
  selectFilters,
  selectSearchDocumentsText,
} from '../../index';

const filler = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

const epic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(GET_DOCUMENTS_DUE_DILIGENCE),
    map(({ payload }: any) => payload),
    withLatestFrom(state$),
    map(([{ page, pageSize, sortBy }, state]) => {
      const filters = selectFilters(state);
      const searchText = selectSearchDocumentsText(state);
      let tipologieFilter = '';
      let progettiFilter = '';
      let documentsSearch = '';
      let isSearch = false;

      if (filters?.tipologie) {
        tipologieFilter = Object.entries(filters.tipologie)
          .filter(([, value]) => value)
          .map(([key]) => key)
          .join('&filterBy=category:');
        if (tipologieFilter?.length > 0) {
          tipologieFilter = `&filterBy=category:${tipologieFilter}`;
        }
      }
      if (filters?.progetti) {
        progettiFilter = Object.entries(filters.progetti)
          .filter(([, value]) => value)
          .map(([key]) => key)
          .join('&filterBy=tag:');
        if (progettiFilter?.length > 0) {
          progettiFilter = `&filterBy=tag:${progettiFilter}`;
        }
      }

      // in case the user used the search box
      if (searchText) {
        isSearch = true;
        documentsSearch = `&q=${searchText}`;
      }

      const qsPayload: any = {
        page,
        pagesize: pageSize,
      };
      if (sortBy) {
        qsPayload.sortBy = sortBy;
      } else {
        qsPayload.sortBy = 'date_upload.desc';
      }
      const qs = `?${querystring.stringify(
        qsPayload
      )}${documentsSearch}${tipologieFilter}${progettiFilter}&filterRule=or`;
      // console.log('[queryString]', qs);
      return { qs, isSearch };
    }),
    switchMap((payload: any) =>
      concat(
        of(setLoaders({ documents: true })),
        timer(0, 5000).pipe(
          takeUntil(action$.pipe(ofType(POLL_DOCUMENTS_STATUS))),
          switchMap(() =>
            from(
              getDocumentsDueDiligenceList(payload.qs, payload.isSearch)
            ).pipe(
              withLatestFrom(state$),
              switchMap(([data, state]: any) =>
                from(
                  Promise.all(
                    data?.results?.map(async (el: any, index: number) => {
                      // let status = null;
                      const prevDocuments = selectDocumentiDueDiligence(state);
                      const prevDocument = prevDocuments?.results?.find(
                        (el2: any) => el2.id === el.id
                      );
                      // status = prevDocument?.status || null;
                      // if (!status || status?.classification?.percentage < 100) {
                      //   const docStatus = await getDocumentDueDiligenceStatus(el.id);
                      //   status = docStatus?.reports;
                      // }

                      return {
                        ...el,
                        language: 'it',
                        filename: el.name,
                        created: el.date_upload,
                        updated: el.date_elaboration
                          ? el.date_elaboration
                          : 'Nessuna modifica',
                      };
                    })
                  )
                ).pipe(
                  map((results: any) => ({
                    ...data,
                    results: [...results, ...filler],
                  }))
                )
              ),
              switchMap((data: any) => {
                const conditionalActions: any = [];
                // if (
                //   !data?.results
                //     ?.filter((el: any) => el.id)
                //     .find(
                //       (el: any) => el.status?.classification?.percentage < 100
                //     )
                // ) {
                //   conditionalActions.push(of(pollDocumentStatus()));
                // }
                conditionalActions.push(of(pollDocumentStatus()));
                return concat(
                  ...conditionalActions,
                  of(getDocumentsDueDiligenceSuccess(data)),
                  of(setLoaders({ documents: false })).pipe(delay(300))
                );
              }),
              catchError(() => EMPTY)
            )
          )
        ),
        of(setLoaders({ documents: false })).pipe(delay(300))
      )
    )
  );

export default epic;
