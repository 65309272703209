import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_EVENTO } from '../../data.const';
import {
  setCurrentEventoAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';

const retrieveEventoEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_EVENTO),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_EVENTO}]`, p)),
    withLatestFrom(state$),
    switchMap(([id, _state]) => {
      return concat(
        of(setLoaders({ eventoLoader: true })),
        defer(() =>
          from(request({ path: `evento/${id}`, method: httpMethods.GET })).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p?.result;
            }),
            // tap(p => console.log(`[epic ${RETRIEVE_EVENTO}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(p => of(setCurrentEventoAction(p))),
            catchError(err => {
              console.error(`[epic ${RETRIEVE_EVENTO}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ eventoLoader: false }))
      );
    })
  );

export default retrieveEventoEpic;
