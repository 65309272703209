import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_GRUPPO_LIST } from '../../data.const';
import {
  setListConfigAction,
  setLoaders,
  setPopupInfo,
  storeGruppoListAction,
} from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';

const retrieveGruppoListEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_GRUPPO_LIST),
    map(() => ({
      page: 1,
      pageSize: rootConfig.populateFields,
      order: [['nome', 'asc']],
    })),
    // tap(p => console.log(`[epic ${RETRIEVE_GRUPPO_LIST}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ gruppoLoader: true })),
        of(setListConfigAction({ role: body })),
        defer(() =>
          from(
            request({
              path: `gruppo/list`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return { total: p?.total, result: [...p?.result] };
            }),
            // tap(p => console.log(`[epic ${RETRIEVE_GRUPPO_LIST}]`, p)),
            // switchMap(() => EMPTY),
            switchMap(data => of(storeGruppoListAction(data))),
            catchError(err => {
              console.error(`[epic ${RETRIEVE_GRUPPO_LIST}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ gruppoLoader: false }))
      );
    })
  );

export default retrieveGruppoListEpic;
